import { get } from "lodash";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

import userPic from "../../../assets/user.png";
import { getUserTypeName, userTypes } from "../../../constant/userManagement";
import {
  deleteUser,
  getUser,
  resetField,
  toggleMainLoader,
} from "../../../store/actions";
import Preloader from "../../../utils/preloader";
import SidePanel from "../../../utils/sidePanel";
import CreateUser from "../create/UserCreate";
import UserListing from "./userListing";

const UserDetails = ({
  match,
  history,
  onEdit = null,
  userDetails,
  resetField,
  getUser,
  toggleMainLoader,
}) => {
  const [data, setData] = useState(null);
  const loggedInAs = (type) => {
    return userDetails.userType === userTypes[type];
  };

  useEffect(() => {
    const successHandler = (e) => {
      setData(e.result);
    };
    userDetails && match && match.params && match.params.id
      ? getUser({ userId: match.params.id }, successHandler)
      : resetField("User.selectedUserDetails", null);
  }, [match, getUser, userDetails, resetField]);

  const [showEditPanel, setShowEditPanel] = useState(false);
  const [isLicenseReallocation, setIsLicenseReallocation] = useState(false);

  const onCreate = () => {
    setShowEditPanel(false);
    setIsLicenseReallocation(false);
  };
  const onCancel = () => {
    setShowEditPanel(false);
    setIsLicenseReallocation(false);
    toggleMainLoader(false);
  };

  const onBackToListing = () => {
    resetField("User.userList", null);
    resetField("User.recordsTotal", null);
    resetField("User.selectedUserDetails", null);
    setTimeout(() => {
      history.goBack();
    });
  };

  return (
    <section className="moovsoon-page-container">
      <div className="moovsoon-page-body">
        <button
          className="btn btn-default btn-sm mr-2 mb-3"
          onClick={onBackToListing}>
          <span className="text-primary">&lt;</span> Back
        </button>
        <div
          className={`user-details-wrap ${data === null ? "" : "loaded mb-3"}`}>
          <Preloader show={data === null} className="inner" />
          {data ? (
            <>
              <div className="user-details-card card card-primary p-4">
                <div className="d-flex align-items-center">
                  <div className="d-flex flex-fill align-items-center">
                    <h1 className="m-0 mr-2">{data.companyName || "--"}</h1>
                  </div>
                  {loggedInAs("SM") ||
                  (loggedInAs("AD") &&
                    data.userType !== userTypes.CA) ? null : (
                    <div>
                      <button
                        type="button"
                        className="btn btn-default btn-md"
                        onClick={() => setShowEditPanel(true)}>
                        <i className="icon icon-edit" />
                        <span className="ml-2">Edit</span>
                      </button>
                      {loggedInAs("AD") && (
                        <button
                          type="button"
                          className="btn btn-default btn-md ml-2"
                          onClick={() => {
                            setShowEditPanel(true);
                            setIsLicenseReallocation(true);
                          }}>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="18"
                            height="18"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            className="lucide lucide-key-square">
                            <path d="M12.4 2.7a2.5 2.5 0 0 1 3.4 0l5.5 5.5a2.5 2.5 0 0 1 0 3.4l-3.7 3.7a2.5 2.5 0 0 1-3.4 0L8.7 9.8a2.5 2.5 0 0 1 0-3.4z" />
                            <path d="m14 7 3 3" />
                            <path d="m9.4 10.6-6.814 6.814A2 2 0 0 0 2 18.828V21a1 1 0 0 0 1 1h3a1 1 0 0 0 1-1v-1a1 1 0 0 1 1-1h1a1 1 0 0 0 1-1v-1a1 1 0 0 1 1-1h.172a2 2 0 0 0 1.414-.586l.814-.814" />
                          </svg>
                          <span className="ml-2">Reallocate License</span>
                        </button>
                      )}
                    </div>
                  )}
                </div>
                {data.address1 || data.address2 ? (
                  <div className="d-flex align-items-center mt-2">
                    <i className="text-gray icon icon-location text-lg" />
                    <span className="ml-2">
                      {data.address1}
                      {data.address2 ? `, ${data.address2}` : ""}
                    </span>
                  </div>
                ) : null}
                <div className="d-flex align-items-center mt-2">
                  <i className="text-gray icon icon-phone text-lg" />
                  <span className="ml-2">{data.officePhoneNo || "--"}</span>
                </div>
                <div className="bb-1 pt-4"></div>
                <h3 className="color-1 text-uppercase py-2 my-2">
                  {getUserTypeName(data.userType)}
                </h3>
                <div className="d-flex align-items-center mb-2">
                  <span className="btn-cell btn-default btn-xl pointer-none">
                    <img src={userPic} alt="User" />
                  </span>
                  <h2 className="m-0 ml-2">{data.fullName || "--"}</h2>
                  <span
                    className={`text-${
                      data.isActive ? "success" : "gray"
                    } ml-2 d-flex align-items-center`}>
                    <i className="icon icon-badge-check mr-1 text-md" />
                    <span>{data.isActive ? "Active" : "Inactive"}</span>
                  </span>
                </div>
                <div className="row">
                  <div className="col-md-4  form-group">
                    <div className="form-value">
                      {data.personalPhoneNo || "--"}
                    </div>
                    <div className="form-label">Mobile Number</div>
                  </div>
                  <div className="col-md-4  form-group">
                    <div className="form-value">
                      {data.emailAddress || "--"}
                    </div>
                    <div className="form-label">Email</div>
                  </div>
                  <div className="col-md-4  form-group">
                    <div className="form-value">{data.market || "--"}</div>
                    <div className="form-label">Market</div>
                  </div>
                </div>
                <div className="bb-1 pt-2"></div>
                <h3 className="color-1 text-uppercase py-2 my-2">
                  Permissions
                </h3>
                <div className="row align-items-start">
                  {data.userType !== userTypes.CA ? (
                    <div className="col-4 form-group">
                      <div className="form-value">
                        {data.accessToTemplates ? "Yes" : "No"}
                      </div>
                      <div className="form-label">Access to Templates</div>
                    </div>
                  ) : null}
                  {data.userType === userTypes.CA ? (
                    <>
                      <div className="col-4 form-group">
                        <div className="form-value">
                          {data.noOfSeats || "--"}
                        </div>
                        <div className="form-label">Number of Seats</div>
                      </div>
                      {data.fullService ? (
                        <div className="col-4 form-group">
                          <div className="form-value">Yes</div>
                          <div className="form-label">Full Service</div>
                        </div>
                      ) : null}
                      {!data.fullService ? (
                        <div className="col-4 form-group">
                          <div className="form-value">
                            {data.homeOwnerDataAccessLimit || "--"}
                          </div>
                          <div className="form-label">
                            Home Owner Data Access Limit
                          </div>
                        </div>
                      ) : null}

                      <div className="col-4 form-group">
                        <div className="form-value">
                          {data.accessToMovescoutPlugin ? "Yes" : "No"}
                        </div>
                        <div className="form-label">
                          Access to Movescout Plugin
                        </div>
                      </div>
                    </>
                  ) : null}
                </div>
                {data.licenseReallocationHistory ? (
                  <>
                    <div className="bb-1 pt-2"></div>
                    <h3 className="color-1 text-uppercase py-2 my-2">
                      License Reallocation History
                    </h3>
                    <div className="row align-items-start mt-4">
                      <div className="col-12">
                        <div className="table-responsive">
                          <table className="table">
                            <thead>
                              <tr>
                                <th>Date</th>
                                <th>Previous User</th>
                                <th>New User</th>
                                <th>Reason</th>
                              </tr>
                            </thead>
                            <tbody>
                              {JSON.parse(data.licenseReallocationHistory).map(
                                (history, index) => (
                                  <tr key={index}>
                                    <td>
                                      {new Date(history.dateOfReallocation)
                                        .toLocaleString("en-US", {
                                          year: "numeric",
                                          month: "numeric",
                                          day: "numeric",
                                          hour: "numeric",
                                          minute: "numeric",
                                          hour12: true,
                                        })
                                        .replace(",", "")}
                                    </td>
                                    <td>
                                      <div>{history.oldUserName}</div>
                                    </td>
                                    <td>
                                      <div>{history.newUserName}</div>
                                    </td>
                                    <td>{history.reason}</td>
                                  </tr>
                                )
                              )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </>
                ) : null}
              </div>
              {data && data.userType !== userTypes.BO ? (
                <UserListing
                  data={data}
                  history={history}
                  listOf={data.userType}
                />
              ) : null}
            </>
          ) : null}
          <SidePanel
            show={showEditPanel}
            onHide={() => {
              setShowEditPanel(false);
              setIsLicenseReallocation(false);
            }}
            component={CreateUser}
            componentProps={{
              initialValues: data,
              profileEdit: false,
              onCreate,
              onEdit,
              onCancel,
              isLicenseReallocation,
            }}
          />
        </div>
      </div>
    </section>
  );
};

const mapStateToProps = (state) => {
  return {
    userDetails: get(state, "Auth.userDetails", {}),
    selectedUserDetails: get(state, "User.selectedUserDetails", null),
  };
};

const mapDispatchToProps = {
  getUser,
  toggleMainLoader,
  resetField,
  deleteUser,
};

export default connect(mapStateToProps, mapDispatchToProps)(UserDetails);
