import { MOVESCOUT } from "../types";

const initialState = {
    leadsList: null,
    totalRecords: 0,
    filterObject: {
        pageIndex: 1,
        pageSize: 10,
        searchKey: "",
        filters: []
    },
    leadOpportunityDetails: null,
    leadPropertyMatch: null,
    loadingState: {
        leadOpportunityDetails: true,
        leadPropertyMatch: true
    }
};

export default (state = initialState, action) => {
    switch (action.type) {
        case MOVESCOUT.GET_LEADS_OPPORTUNITIES:
            return {
                ...state,
                leadsList: action.payload?.result?.data || [],
                totalRecords: action.payload?.result?.recordsTotal || 0
            };
        case MOVESCOUT.SET_LEADS_OPPORTUNITIES_FILTER:
            return {
                ...state,
                filterObject: action.payload
            };
        case MOVESCOUT.GET_LEAD_OPPORTUNITY_DETAILS_BY_LEAD_ID:
            return {
                ...state,
                leadOpportunityDetails: action.payload?.result || null,
                loadingState: {
                    ...state.loadingState,
                    leadOpportunityDetails: false
                }
            };
        case MOVESCOUT.RESET_LEAD_OPPORTUNITY_DETAILS_LOADING_STATE:
            return {
                ...state,
                loadingState: {
                    ...state.loadingState,
                    leadOpportunityDetails: true
                }
            };
        case MOVESCOUT.SET_LEAD_OPPORTUNITY_DETAILS_LOADING_STATE:
            return {
                ...state,
                loadingState: {
                    ...state.loadingState,
                    leadOpportunityDetails: action.payload
                }
            };
        case MOVESCOUT.GET_LEAD_PROPERTY_MATCH:
            return {
                ...state,
                leadPropertyMatch: action.payload?.result || null,
                loadingState: {
                    ...state.loadingState,
                    leadPropertyMatch: false
                }
            };
        case MOVESCOUT.RESET_LEAD_PROPERTY_MATCH_LOADING_STATE:
            return {
                ...state,
                loadingState: {
                    ...state.loadingState,
                    leadPropertyMatch: true
                }
            };
        case MOVESCOUT.SET_LEAD_PROPERTY_MATCH_LOADING_STATE:
            return {
                ...state,
                loadingState: {
                    ...state.loadingState,
                    leadPropertyMatch: action.payload
                }
            };
        default:
            return state;
    }
};
