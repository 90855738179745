export const CAMPAIGN_TYPES = {
    PRE_SALE: {
        label: "Pre-Sale",
        value: 1
    },
    RE_ENGAGE: {
        label: "Re-Engage",
        value: 2
    },
    CLOSER: {
        label: "Closer",
        value: 3
    }
}


export const TIMING_OPTIONS = {
    ONE_DAY_AFTER: { label: "1 Day After", value: 1 },
    ONE_DAY_BEFORE: { label: "1 Day Before", value: 2 },
    TWO_DAYS_AFTER: { label: "2 Days After", value: 3 },
    TWO_DAYS_BEFORE: { label: "2 Days Before", value: 4 },
    THREE_DAYS_AFTER: { label: "3 Days After", value: 5 },
    THREE_DAYS_BEFORE: { label: "3 Days Before", value: 6 },
    FOUR_DAYS_AFTER: { label: "4 Days After", value: 7 },
    FOUR_DAYS_BEFORE: { label: "4 Days Before", value: 8 },
    FIVE_DAYS_AFTER: { label: "5 Days After", value: 9 },
    FIVE_DAYS_BEFORE: { label: "5 Days Before", value: 10 },
    SIX_DAYS_AFTER: { label: "6 Days After", value: 11 },
    SIX_DAYS_BEFORE: { label: "6 Days Before", value: 12 },
    SEVEN_DAYS_AFTER: { label: "7 Days After", value: 13 },
    SEVEN_DAYS_BEFORE: { label: "7 Days Before", value: 14 },
};

export const CAMPAIGN_EVENT_OPTIONS = {
    SCHEDULED_SURVEY: { label: "Scheduled Survey", value: 1 },
    SURVEY_COMPLETE: { label: "Survey Complete", value: 2 },
    INITIAL_INQUIRY: { label: "Initial inquiry with no survey scheduled", value: 3 },
    EMAIL_1: { label: "1st Email", value: 4 },
    EMAIL_2: { label: "2nd Email", value: 5 },
};

export const TEMPLATE_TYPE_OPTIONS = {
    EMAIL: { label: "Email", value: 1 },
    SMS: { label: "SMS", value: 2 },
    DIRECT_MAIL: { label: "Direct Mail", value: 3 },
};

export const MOVE_TYPE_OPTIONS = [
    { value: 1, label: "Interstate" },
    { value: 2, label: "Local" },
    { value: 3, label: "International" }
];