import fetchHandler from "../../utils/fetchHandler";
import { TEMPLATE } from "../types";

export const getTemplates = (values, successHandler, errorHandler) => {
  const fetchOptions = {
    url: `templates/get-templateslist-with-pagination`,
    method: "POST",
    secure: true,
    actionType: TEMPLATE.GET_TEMPLATE_LIST,
    body: JSON.stringify(values),
  };
  return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const getCompanyTemplates = (successHandler, errorHandler) => {
  const fetchOptions = {
    url: `templates/get-company-template-list`,
    method: "GET",
    secure: true,
    actionType: TEMPLATE.GET_COMPANY_TEMPLATE_LIST,
  };
  return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const createTemplate = (values, successHandler, errorHandler) => {
  const fetchOptions = {
    url: `templates/create-template`,
    method: "POST",
    secure: true,
    actionType: TEMPLATE.CREATE_TEMPLATE,
    body: JSON.stringify(values),
  };
  return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const updateTemplate = (values, successHandler, errorHandler) => {
  const fetchOptions = {
    url: `templates/update-template`,
    method: "POST",
    secure: true,
    actionType: TEMPLATE.UPDATE_TEMPLATE,
    body: JSON.stringify(values),
  };
  return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const getTemplateDetails = (values, successHandler, errorHandler) => {
  const fetchOptions = {
    url: `templates/get-template/${values.templateId}`,
    method: "GET",
    secure: true,
    actionType: TEMPLATE.GET_TEMPLATE_DETAILS,
  };
  return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const getTemplateCompanyDetails = (successHandler, errorHandler) => {
  const fetchOptions = {
    url: `templates/get-company-details`,
    method: "GET",
    secure: true,
    actionType: TEMPLATE.GET_TEMPLATE_COMPANY_DETAILS,
  };
  return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const getTemplateProspectingEvents = (
  values,
  successHandler,
  errorHandler
) => {
  const fetchOptions = {
    url: `templates/get-prospecting-events?outboundCommunicationType=${values.templateType}`,
    method: "POST",
    secure: true,
    actionType: TEMPLATE.GET_TEMPLATE_PROSPECTING_EVENTS,
  };
  return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const deleteTemplate = (values, successHandler, errorHandler) => {
  const fetchOptions = {
    url: `templates/delete-template?templateId=${values.templateId}`,
    method: "DELETE",
    secure: true,
    actionType: TEMPLATE.DELETE_TEMPLATE,
    body: JSON.stringify(values),
  };
  return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const uploadPostCardImage = (values, successHandler, errorHandler) => {
  const fetchOptions = {
    url: `templates/upload-post-card-image`,
    method: "POST",
    secure: true,
    actionType: TEMPLATE.UPLOAD_POST_CARD_IMAGE,
    body: JSON.stringify(values),
  };
  return fetchHandler(fetchOptions, successHandler, errorHandler);
};
export const getMailerSizeOptions = (successHandler, errorHandler) => {
  const fetchOptions = {
    url: `templates/get-template-size`,
    method: "GET",
    secure: true,
    actionType: TEMPLATE.GET_MAILER_SIZE,
  };
  return fetchHandler(fetchOptions, successHandler, errorHandler);
};
