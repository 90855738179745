import { combineReducers } from "redux";
import { reducer as formReducer } from "redux-form";
import { update } from "lodash";

import UI from "./uiReducer";
import Auth from "./authReducer";
import User from "./userReducer";
import Market from "./marketReducer";
import Settings from "./settingsReducer";
import Template from "./templateReducer";
import Property from "./propertyReducer";
import Report from "./reportReducer";
import Dashboard from "./dashboardReducer";
import Realtor from "./realtorReducer";
import MovescoutLeads from "./movescoutLeadsReducer";
import { COMMON } from "../types";

const allReducers = combineReducers({
	form: formReducer,
	UI,
	Auth,
	User,
	Market,
	Settings,
	Template,
	Property,
	Report,
	Dashboard,
	Realtor,
	MovescoutLeads,
});

const rootReducer = (state, action) => {
	let newState = { ...state };
	switch (action.type) {
		case COMMON.RESET_FIELD:
			if (action.key) {
				newState = update(state, action.key, () => action.value);
			}
			return {
				...newState,
			};
		default:
		// do nothing;
	}
	return allReducers(
		action.type === COMMON.LOGOUT_APP
			? { UI: { mainPreloader: false } }
			: newState,
		action
	);
};

export default rootReducer;
