import React from "react";
import { Date } from "../../../utils/formatter";
import Tooltip from "../../../utils/tooltip";



const getTooltipData = (row) => {
    switch (row.key) {
        case "leadStatus":
        case "moveType":
            return row.label;
        case "datecreatedfrom":
            return <Date utcTime={row.value} />;
        case "datecreatedto":
            return <Date utcTime={row.value} />;
        case "datemodifiedfrom":
            return <Date utcTime={row.value} />;
        case "datemodifiedto":
            return <Date utcTime={row.value} />;
        default:
            return row.label;
    }
};


const getFilterLabel = (item) => {
    switch (item.key) {
        case "leadstatus":
            return "Lead Status";
        case "movetype":
            return "Move Type";
        default:
            return item.label;
    }
}

const AppliedFiltersList = ({
    filterObject,
    setLeadsOpportunitiesFilter,
    setFilterSelectedValues,
    setSelectedDate,
    setSelectedModifiedDate,
    filterSelectedValues,
    selectedDate,
    selectedModifiedDate
}) => {
    const removeFilter = (filterKey = "") => {
        if (filterKey === "") {
            setLeadsOpportunitiesFilter({
                ...filterObject,
                filters: []
            });
            setFilterSelectedValues({});
            setSelectedDate({});
            setSelectedModifiedDate({});
        } else {
            const filterSet = filterObject.filters.filter((item) => item.key !== filterKey);
            setLeadsOpportunitiesFilter({
                ...filterObject,
                filters: filterSet
            });

            let filteredFormValues = { ...filterSelectedValues };
            if (filteredFormValues.hasOwnProperty(filterKey)) {
                delete filteredFormValues[filterKey];
            }
            setFilterSelectedValues(filteredFormValues);

            // Reset date states if date filters are removed
            if (filterKey === "datecreatedfrom") {
                setSelectedDate({
                    ...selectedDate,
                    from: ""
                });
            }
            if (filterKey === "datecreatedto") {
                setSelectedDate({
                    ...selectedDate,
                    to: ""
                });
            }
            if (filterKey === "datemodifiedfrom") {
                setSelectedModifiedDate({
                    ...selectedModifiedDate,
                    from: ""
                });
            }
            if (filterKey === "datemodifiedto") {
                setSelectedModifiedDate({
                    ...selectedModifiedDate,
                    to: ""
                });
            }
        }
    };




    return (
        <div>
            {filterObject && filterObject.filters && filterObject.filters.length ? (
                <div className="row align-items-start">
                    <div className="col-md-12 form-group">
                        <div className="form-value">
                            {filterObject.filters
                                .filter((item) => item.value != null)
                                .map((item, index) => (
                                    <Tooltip
                                        message={getTooltipData(item)}
                                        key={index}
                                        component={
                                            <div className="badge badge-secondary badge-sm mr-2">
                                                {getFilterLabel(item)}
                                                <span
                                                    onClick={() => removeFilter(item.key)}
                                                    className="remove-filter"
                                                >
                                                    X
                                                </span>
                                            </div>
                                        }
                                    />
                                ))}
                            <span
                                className="text-primary pointer"
                                onClick={() => removeFilter()}
                            >
                                Reset Filter
                            </span>
                        </div>
                    </div>
                </div>
            ) : null}
        </div>
    );
};

export default AppliedFiltersList; 