import { get } from "lodash";
import queryString from "query-string";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Pagination, Table } from "../../../partials/table/table";
import {
  getLeadPropertyMatch,
  getLeadsOpportunities,
  resetLeadPropertyMatchLoadingState,
  setLeadsOpportunitiesFilter,
  toggleMainLoader,
} from "../../../store/actions";
import { errorMessage } from "../../../utils/errorMessage";
import FilterForm from "../../../utils/filterForm";
import SearchField from "../../../utils/searchTable";
import SidePanel from "../../../utils/sidePanel";
import TableLoader from "../../../utils/tableLoader";
import { IconButton } from "../../../utils/uiElements";
import AppliedFiltersList from "./AppliedFiltersList";
import { capitalizeWords, filtersArray, generateColumns } from "./helper";
import "./movescout.scss";

function MoveScout({
  location,
  history,
  getLeadsOpportunities,
  leadsList,
  totalRecords,
  toggleMainLoader,
  filterObject,
  setLeadsOpportunitiesFilter,
  getLeadPropertyMatch,
  resetLeadPropertyMatchLoadingState,
}) {
  const [fromLeadDetails, setFromLeadDetails] = useState(
    location.state?.fromLeadDetails
  );
  const [filterPanelDisplay, showFilterPanel] = useState(false);
  const [selectedDate, setSelectedDate] = useState({});
  const [selectedModifiedDate, setSelectedModifiedDate] = useState({});
  const [filterSelectedValues, setFilterSelectedValues] = useState({});
  const [expandedRows, setExpandedRows] = useState({});

  const queryParams = queryString.parse(location.search);

  useEffect(() => {
    if (queryParams.search && queryParams.search !== filterObject.searchKey) {
      setLeadsOpportunitiesFilter({
        ...filterObject,
        searchKey: queryParams.search,
        pageIndex: 1,
      });
    }
  }, []);

  const onSelectRow = (row) => {
    const currentSearch = filterObject.searchKey;
    const searchParam = currentSearch ? `?from=${currentSearch}` : "";
    history.push(
      `/plugins/movescout/leads/${row.opportunityId}/view${searchParam}`
    );
  };

  const onToggleExpand = (rowIndex) => {
    const expandedRowsNew = { ...expandedRows };
    expandedRowsNew[rowIndex] = !expandedRowsNew[rowIndex];
    setExpandedRows(expandedRowsNew);
  };

  const columns = generateColumns({
    onSelectRow,
    onToggleExpand,
    getLeadPropertyMatch,
    resetLeadPropertyMatchLoadingState,
  });

  const onPaginate = (pageIndex) => {
    setLeadsOpportunitiesFilter({
      ...filterObject,
      pageIndex,
    });
  };

  const onSearch = (searchKey) => {
    const newUrl = searchKey
      ? `${location.pathname}?search=${searchKey}`
      : location.pathname;

    history.replace(newUrl);

    setLeadsOpportunitiesFilter({
      ...filterObject,
      searchKey,
      pageIndex: 1,
    });
  };

  const ExpandedRowComponent = (row) => {
    const payload = JSON.parse(row.payload || "{}");

    return (
      <div className="expanded-lead-details">
        <div className="row">
          <div className="col-md-3 form-group">
            <div className="form-value">{payload.OriginAddress || "N/A"}</div>
            <label className="form-label">Origin Address</label>
          </div>
          <div className="col-md-3 form-group">
            <div className="form-value">{payload.OriginCity ? capitalizeWords(payload.OriginCity) : "N/A"}</div>
            <label className="form-label">Origin City</label>
          </div>
          <div className="col-md-3 form-group">
            <div className="form-value">{payload.OriginState || "N/A"}</div>
            <label className="form-label">Origin State</label>
          </div>
          <div className="col-md-3 form-group">
            <div className="form-value">{payload.OriginZip || "N/A"}</div>
            <label className="form-label">Origin Zip</label>
          </div>
        </div>
        <div className="row">
          <div className="col-md-3 form-group">
            <div className="form-value">
              {payload.DestinationAddress || "N/A"}
            </div>
            <label className="form-label">Destination Address</label>
          </div>
          <div className="col-md-3 form-group">
            <div className="form-value">{payload.DestinationCity ? capitalizeWords(payload.DestinationCity) : "N/A"}</div>
            <label className="form-label">Destination City</label>
          </div>
          <div className="col-md-3 form-group">
            <div className="form-value">
              {payload.DestinationState || "N/A"}
            </div>
            <label className="form-label">Destination State</label>
          </div>
          <div className="col-md-3 form-group">
            <div className="form-value">{payload.DestinationZip || "N/A"}</div>
            <label className="form-label">Destination Zip</label>
          </div>
        </div>
        <div className="row">
          <div className="col-md-3 form-group">
            <div className="form-value">{row.opportunityType || "N/A"}</div>
            <label className="form-label">Opportunity Type</label>
          </div>
        </div>
      </div>
    );
  };

  const onApplyFilter = (formValues = null) => {
    showFilterPanel(false);
    setFilterSelectedValues(formValues);

    if (!formValues) {
      setLeadsOpportunitiesFilter({
        ...filterObject,
        filters: [],
        pageIndex: 1,
      });
      return;
    }

    // Separate date filters for better readability
    const dateFilters = [
      {
        key: "datecreatedfrom",
        value: formValues.datecreatedfrom,
        label: "Date Created From",
      },
      {
        key: "datecreatedto",
        value: formValues.datecreatedto,
        label: "Date Created To",
      },
      {
        key: "datemodifiedfrom",
        value: formValues.datemodifiedfrom,
        label: "Date Modified From",
      },
      {
        key: "datemodifiedto",
        value: formValues.datemodifiedto,
        label: "Date Modified To",
      },
    ].filter((filter) => filter.value);

    const otherFilters = Object.entries(formValues)
      .filter(([key, value]) => {
        return (
          value &&
          ![
            "datecreatedfrom",
            "datecreatedto",
            "datemodifiedfrom",
            "datemodifiedto",
          ].includes(key) &&
          value.value
        );
      })
      .map(([key, value]) => ({
        key,
        value: value.value,
        label: value.label,
      }));

    // Combine all filters
    const combinedFilters = [...dateFilters, ...otherFilters];

    // Update filter state
    setLeadsOpportunitiesFilter({
      ...filterObject,
      filters: combinedFilters,
      pageIndex: 1,
    });
  };

  useEffect(() => {
    if (fromLeadDetails && leadsList) {
      toggleMainLoader(false);
      setFromLeadDetails(false);
      return;
    }

    const successHandler = (response) => {
      toggleMainLoader(false);
      setFromLeadDetails(false);
    };

    const errorHandler = (error) => {
      errorMessage(error);
      toggleMainLoader(false);
    };

    toggleMainLoader(true);
    let leadsController = getLeadsOpportunities(
      {
        pageSize: filterObject.pageSize,
        pageIndex: filterObject.pageIndex,
        searchKey: filterObject.searchKey,
        filters: filterObject.filters || [],
      },
      successHandler,
      errorHandler
    );

    return () => {
      if (leadsController?.abort) {
        leadsController.abort();
      }
    };
  }, [filterObject, getLeadsOpportunities, toggleMainLoader]);

  return (
    <section className="moovsoon-page-container">
      <div className="d-flex align-items-center mb-3">
        <h2 className="mr-3 mb-0">Leads</h2>
        <SearchField
          initialValue={filterObject.searchKey || queryParams.search || ""}
          onSearch={onSearch}
          className="flex-fill mr-3 form-group mb-0"
          placeholder="Enter your query"
        />
        <div className="d-flex align-items-center">
          <IconButton
            icon="filter"
            type="button"
            value="Filters"
            className="btn btn-primary btn-sm"
            onClick={() => showFilterPanel(true)}
          />
        </div>
      </div>

      <AppliedFiltersList
        filterObject={filterObject}
        setLeadsOpportunitiesFilter={setLeadsOpportunitiesFilter}
        setFilterSelectedValues={setFilterSelectedValues}
        setSelectedDate={setSelectedDate}
        setSelectedModifiedDate={setSelectedModifiedDate}
        filterSelectedValues={filterSelectedValues}
        selectedDate={selectedDate}
        selectedModifiedDate={selectedModifiedDate}
      />

      {leadsList && leadsList.length ? (
        <div>
          <Table
            columns={columns}
            data={leadsList}
            expandableRows={true}
            expandedComponent={ExpandedRowComponent}
            expandedRows={expandedRows}
            onToggleExpand={onToggleExpand}
          />
          <Pagination
            total={totalRecords || 0}
            count={filterObject.pageSize}
            page={filterObject.pageIndex}
            onChange={onPaginate}
          />
        </div>
      ) : null}
      <TableLoader list={leadsList} />

      <SidePanel
        show={filterPanelDisplay}
        onHide={() => showFilterPanel(false)}
        component={FilterForm}
        componentProps={{
          onApplyFilter,
          onCancelFilter: () => showFilterPanel(false),
          filtersArray: filtersArray({
            selectedDate,
            setSelectedDate,
            selectedModifiedDate,
            setSelectedModifiedDate,
            filterSelectedValues,
          }),
          initialValues: filterSelectedValues || null,
        }}
      />
    </section>
  );
}

const mapStateToProps = (state) => ({
  leadsList: get(state, "MovescoutLeads.leadsList", null),
  totalRecords: get(state, "MovescoutLeads.totalRecords", 0),
  filterObject: get(state, "MovescoutLeads.filterObject", {
    pageIndex: 1,
    pageSize: 10,
  }),
});

const mapDispatchToProps = {
  getLeadsOpportunities,
  toggleMainLoader,
  setLeadsOpportunitiesFilter,
  getLeadPropertyMatch,
  resetLeadPropertyMatchLoadingState,
};

export default connect(mapStateToProps, mapDispatchToProps)(MoveScout);
