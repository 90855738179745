import React, { useEffect, useReducer, useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { errorMessage } from "../../../utils/errorMessage";
import { successMessage } from "../../../utils/successMessage";
import "./campaignCreate.scss";
import { CampaignType, campaignTypes, TouchPoint } from "./helper";
import {
    getCompanyTemplates,
    toggleMainLoader,
    createCampaign,
    getCampaignById,
    updateCampaign
} from "../../../store/actions";

// Initial state for the reducer
const initialStates = {
    error: null,
    submitting: false,
    companyTemplates: [],
    loading: false,
    campaign: null
};

// Reducer function to manage component state
const reducer = (state, action) => {
    return { ...state, ...action };
};

const CampaignCreate = ({
    match,
    initialValues = null,
    toggleMainLoader,
    getCompanyTemplates,
    createCampaign,
    getCampaignById,
    updateCampaign
}) => {
    const history = useHistory();
    const isEditMode = match.path.includes("/edit");
    const campaignId = match.params.id;
    const [state, dispatch] = useReducer(reducer, initialStates);
    const { error, submitting, companyTemplates, loading, campaign } = state;

    const [selectedType, setSelectedType] = useState(null);
    const [touchpoints, setTouchpoints] = useState([{}]);
    const [campaignName, setCampaignName] = useState("");
    const [formErrors, setFormErrors] = useState({
        campaignName: "",
        touchpoints: "",
    });
    const [formDirty, setFormDirty] = useState(false);

    // Track initial values to determine if form is dirty
    const [initialFormState, setInitialFormState] = useState({
        campaignType: null,
        campaignName: "",
        touchpoints: [{}]
    });

    // Fetch company templates when component mounts
    useEffect(() => {
        const successHandler = (response) => {
            if (response && response.result) {
                dispatch({ companyTemplates: response.result });
            }
        };

        const errorHandler = (error) => {
            console.error("Error fetching company templates:", error);
            errorMessage("Failed to load templates");
        };

        getCompanyTemplates(successHandler, errorHandler);
    }, [getCompanyTemplates]);

    // Fetch campaign data if in edit mode
    useEffect(() => {
        if (isEditMode && campaignId) {
            dispatch({ loading: true });
            toggleMainLoader(true);

            getCampaignById(
                campaignId,
                (response) => {
                    if (response && response.result) {
                        const campaignData = response.result;
                        dispatch({ campaign: campaignData, loading: false });

                        // Parse touchpoints from JSON string if needed
                        let parsedTouchpoints = [];
                        try {
                            if (typeof campaignData.touchpoints === 'string') {
                                parsedTouchpoints = JSON.parse(campaignData.touchpoints);

                                // Convert the property names to lowercase for consistency
                                parsedTouchpoints = parsedTouchpoints.map(tp => ({
                                    timing: tp.Timing || tp.timing || "",
                                    event: tp.Event || tp.event || "",
                                    type: tp.Type || tp.type || "",
                                    template: tp.Template || tp.template || ""
                                }));
                            } else if (Array.isArray(campaignData.touchpoints)) {
                                parsedTouchpoints = campaignData.touchpoints;
                            }
                        } catch (e) {
                            console.error("Error parsing touchpoints:", e);
                            parsedTouchpoints = [];
                        }

                        // Set form values
                        setSelectedType(campaignData.type);
                        setCampaignName(campaignData.templateName);

                        // If touchpoints array is empty, add at least one empty touchpoint
                        if (!parsedTouchpoints || parsedTouchpoints.length === 0) {
                            setTouchpoints([{}]);
                        } else {
                            setTouchpoints(parsedTouchpoints);
                        }

                        // Set initial form state for dirty checking
                        setInitialFormState({
                            campaignType: campaignData.type,
                            campaignName: campaignData.templateName,
                            touchpoints: !parsedTouchpoints || parsedTouchpoints.length === 0 ?
                                [{}] : parsedTouchpoints
                        });

                        toggleMainLoader(false);
                    }
                },
                (error) => {
                    console.error("Error fetching campaign:", error);
                    errorMessage("Failed to load campaign");
                    dispatch({ loading: false });
                    toggleMainLoader(false);
                    history.push("/campaigns");
                }
            );
        }
    }, [isEditMode, campaignId, getCampaignById, toggleMainLoader, history]);

    // Check if form is dirty when values change
    useEffect(() => {
        const currentState = {
            campaignType: selectedType,
            campaignName: campaignName,
            touchpoints: touchpoints
        };

        // Simple deep comparison
        const isDirty = JSON.stringify(currentState) !== JSON.stringify(initialFormState);
        setFormDirty(isDirty);
    }, [selectedType, campaignName, touchpoints, initialFormState]);

    const handleBack = () => {
        history.push("/campaigns");
    };

    const addTouchpoint = () => {
        setTouchpoints([...touchpoints, {}]);
    };

    const deleteTouchpoint = (index) => {
        const newTouchpoints = touchpoints.filter((_, i) => i !== index);
        setTouchpoints(newTouchpoints);
    };

    const getSelectedCampaignTitle = () => {
        const selected = campaignTypes.find((type) => type.type === selectedType);
        return selected ? selected.title : "";
    };

    const isValidTouchpoint = (touchpoint) => {
        return (
            touchpoint.timing &&
            touchpoint.event &&
            touchpoint.type &&
            touchpoint.template
        );
    };

    const validateForm = () => {
        let isValid = true;
        const errors = {
            campaignName: "",
            touchpoints: "",
        };

        // Validate campaign name
        if (!campaignName) {
            errors.campaignName = "Campaign name is required";
            isValid = false;
        } else if (campaignName.length < 3) {
            errors.campaignName = "Campaign name must be at least 3 characters";
            isValid = false;
        }

        // Validate touchpoints
        if (touchpoints.length === 0) {
            errors.touchpoints = "At least one touchpoint is required";
            isValid = false;
        } else {
            // Check if all touchpoint fields are filled
            const invalidTouchpoints = touchpoints.filter(tp => !isValidTouchpoint(tp));

            if (invalidTouchpoints.length > 0) {
                errors.touchpoints = "All touchpoint fields must be filled";
                isValid = false;
            }
        }

        setFormErrors(errors);
        return isValid;
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        // Validate form
        if (!validateForm()) {
            if (formErrors.campaignName) {
                errorMessage(formErrors.campaignName);
            } else if (formErrors.touchpoints) {
                errorMessage(formErrors.touchpoints);
            }
            return;
        }

        // Check if any changes have been made
        if (!formDirty) {
            errorMessage("NO_CHANGE");
            return;
        }

        // Change cursor to hourglass
        document.body.style.cursor = "wait";

        // Set submitting state and show loader
        toggleMainLoader(true);
        dispatch({ submitting: true });

        // Format the data for submission
        const formattedData = {
            templateName: campaignName,
            type: selectedType,
            touchpoints: touchpoints.map((touchpoint) => ({
                timing: touchpoint.timing || "",
                event: touchpoint.event || "",
                type: touchpoint.type || "",
                template: touchpoint.template || "",
            })),
        };

        if (isEditMode && campaignId) {
            // Update existing campaign using the API
            updateCampaign(
                campaignId,
                formattedData,
                (response) => {
                    toggleMainLoader(false);
                    dispatch({ submitting: false });
                    document.body.style.cursor = "default";
                    successMessage("Campaign updated successfully");
                    history.push("/campaigns");
                },
                (error) => {
                    console.error("Error updating campaign:", error);
                    toggleMainLoader(false);
                    dispatch({
                        error: "An error occurred while updating the campaign",
                        submitting: false,
                    });
                    document.body.style.cursor = "default";
                    errorMessage(error.message || "Failed to update campaign");
                }
            );
        } else {
            // Create new campaign
            createCampaign(
                formattedData,
                (response) => {
                    toggleMainLoader(false);
                    dispatch({ submitting: false });
                    document.body.style.cursor = "default";
                    successMessage("Campaign created successfully");
                    history.push("/campaigns");
                },
                (error) => {
                    console.error("Error submitting campaign:", error);
                    toggleMainLoader(false);
                    dispatch({
                        error: "An error occurred while saving the campaign",
                        submitting: false,
                    });
                    document.body.style.cursor = "default";
                    errorMessage(error.message || "Failed to create campaign");
                }
            );
        }
    };

    // Get filtered templates based on selected type
    const getFilteredTemplates = (templateType) => {
        if (!companyTemplates || !companyTemplates.length) return [];

        return companyTemplates.filter(template => template.templateType === parseInt(templateType));
    };

    // Pass filtered templates to TouchPoint component
    const handleTouchpointChange = (index, field, value) => {
        const updatedTouchpoints = [...touchpoints];
        updatedTouchpoints[index] = {
            ...updatedTouchpoints[index],
            [field]: value
        };

        // Clear template selection if type changes
        if (field === 'type') {
            updatedTouchpoints[index].template = '';
        }

        setTouchpoints(updatedTouchpoints);
    };

    const handleCampaignNameChange = (e) => {
        setCampaignName(e.target.value);
        // Clear error when user types
        if (formErrors.campaignName && e.target.value.length >= 3) {
            setFormErrors({
                ...formErrors,
                campaignName: "",
            });
        }
    };

    return (
        <div className="campaigns-page">
            <button
                type="button"
                className="btn btn-default btn-sm mr-2 mb-3"
                onClick={handleBack}>
                <span>&lt;</span> Back
            </button>
            <div className="campaigns-header">
                <h1>{isEditMode ? "Edit Campaign" : "Create Campaign"}</h1>
            </div>

            <form
                onSubmit={handleSubmit}
                className="campaign-create-content">
                <h2>Select a Campaign Type</h2>

                <div className="campaign-types">
                    {campaignTypes.map((type) => (
                        <CampaignType
                            key={type.type}
                            {...type}
                            selected={selectedType === type.type}
                            onClick={() => {
                                setSelectedType(type.type);
                            }}
                        />
                    ))}
                </div>

                {selectedType && (
                    <>
                        <h2 className="campaign-type-header">
                            {getSelectedCampaignTitle()} Campaign
                        </h2>

                        <div className="campaign-form">
                            <div className="form-group">
                                <input
                                    name="campaignName"
                                    type="text"
                                    className={`form-control ${formErrors.campaignName ? 'is-invalid' : ''}`}
                                    placeholder="Add Campaign Name"
                                    value={campaignName}
                                    onChange={handleCampaignNameChange}
                                />
                                <label>Campaign Name</label>
                                {formErrors.campaignName && (
                                    <div className="text-danger mt-2">{formErrors.campaignName}</div>
                                )}
                            </div>

                            <div className="touchpoints-section">
                                <div className="touchpoints-header">
                                    <h3>Touchpoints</h3>
                                    <button
                                        type="button"
                                        className="btn btn-primary"
                                        onClick={addTouchpoint}>
                                        + Add Touchpoint
                                    </button>
                                </div>

                                <div className="touchpoints-list">
                                    {touchpoints.map((touchpoint, index) => (
                                        <TouchPoint
                                            key={index}
                                            index={index}
                                            touchpoint={touchpoint}
                                            onChange={handleTouchpointChange}
                                            onDelete={() => deleteTouchpoint(index)}
                                            onPreview={() => { }}
                                            hasError={!!formErrors.touchpoints}
                                            availableTemplates={getFilteredTemplates(touchpoint.type)}
                                        />
                                    ))}
                                </div>
                                {formErrors.touchpoints && (
                                    <div className="text-danger mt-2">{formErrors.touchpoints}</div>
                                )}
                            </div>

                        </div>

                        <div className="form-footer">
                            <button
                                type="button"
                                className="btn btn-default mt-3 mr-2"
                                onClick={handleBack}>
                                Cancel
                            </button>
                            <button
                                type="submit"
                                className="btn btn-primary mt-3"
                                disabled={submitting}>
                                {submitting ? "Saving..." : isEditMode ? "Update Campaign" : "Save Campaign"}
                            </button>

                            {error && (
                                <div className="error small text-danger text-center mt-3">
                                    {error}
                                </div>
                            )}
                        </div>
                    </>
                )}
            </form>
        </div>
    );
};

const mapStateToProps = (state) => ({
    // Your existing state mapping
});

const mapDispatchToProps = {
    toggleMainLoader,
    getCompanyTemplates,
    createCampaign,
    getCampaignById,
    updateCampaign
};

// Connect component to Redux without Redux Form
export default connect(mapStateToProps, mapDispatchToProps)(CampaignCreate);
