import React, { useState, useEffect } from "react";
import { reduxForm } from "redux-form";
import { Pagination, Table } from "../../partials/table/table";
import InfoModal from "../../utils/infoModal";
import NavTabs from "../../utils/navTabs";
import "./campaigns.scss";

import TableLoader from "../../utils/tableLoader";
import Tooltip from "../../utils/tooltip";
import {
  getCampaignsByType,
  deleteCampaign,
  getAutomatedCampaigns,
  deleteAutomatedCampaign
} from "../../store/actions";
import { connect } from "react-redux";
import { automatedColumns, getCampaignColumns } from './helper';
import CreateAutomatedCampaignModal from './CreateAutomatedCampaignModal';
import { errorMessage } from "../../utils/errorMessage";
import { successMessage } from "../../utils/successMessage";

const navData = [
  {
    label: "Pre-sale",
    icon: "icon icon-inbox",
  },
  {
    label: "Re-engage",
    icon: "icon icon-sms",
  },
  {
    label: "Closer",
    icon: "icon icon-wallet",
  },
];

export const mockData = {
  "Pre-sale": [

  ],
  "Re-engage": [

  ],
  Closer: [

  ],
};

const paginateData = (data, pageIndex, pageSize) => {
  const startIndex = (pageIndex - 1) * pageSize;
  const endIndex = startIndex + pageSize;
  return data.slice(startIndex, endIndex);
};

const Campaigns = ({
  change,
  history,
  getCampaignsByType,
  deleteCampaign,
  getAutomatedCampaigns,
  deleteAutomatedCampaign
}) => {
  const [currentTab, setCurrentTab] = useState(0);
  const [searchKey, setSearchKey] = useState("");
  const [automationEnabled, setAutomationEnabled] = useState(false);
  const [showAutomationModal, setShowAutomationModal] = useState(false);
  const [automatedPageIndex, setAutomatedPageIndex] = useState(1);
  const [campaignsPageIndex, setCampaignsPageIndex] = useState(1);
  const [campaigns, setCampaigns] = useState([]);
  const [isLoadingCampaigns, setIsLoadingCampaigns] = useState(true);
  const [campaignToDelete, setCampaignToDelete] = useState(null);
  const [showDeleteConfirmModal, setShowDeleteConfirmModal] = useState(false);
  const [automatedCampaigns, setAutomatedCampaigns] = useState([]);
  const [isLoadingAutomatedCampaigns, setIsLoadingAutomatedCampaigns] = useState(true);
  const [automatedCampaignToDelete, setAutomatedCampaignToDelete] = useState(null);
  const [showAutomatedDeleteConfirmModal, setShowAutomatedDeleteConfirmModal] = useState(false);
  const [isDeleteCampaignLoading, setIsDeleteCampaignLoading] = useState(false);
  const [isDeleteAutomatedCampaignLoading, setIsDeleteAutomatedCampaignLoading] = useState(false);
  const [automatedCampaignToEdit, setAutomatedCampaignToEdit] = useState(null);
  const pageSize = 10;

  // Fetch regular campaigns
  useEffect(() => {
    const typeMap = {
      0: 1, // Pre-sale
      1: 2, // Re-engage
      2: 3  // Closer
    };

    const campaignType = typeMap[currentTab];
    setIsLoadingCampaigns(true);

    getCampaignsByType(
      campaignType,
      (response) => {
        setCampaigns(response.result || []);
        setIsLoadingCampaigns(false);
      },
      (error) => {
        console.error('Error fetching campaigns:', error);
        setCampaigns([]);
        setIsLoadingCampaigns(false);
      }
    );
  }, [currentTab, getCampaignsByType]);

  // Fetch automated campaigns
  useEffect(() => {
    setIsLoadingAutomatedCampaigns(true);

    getAutomatedCampaigns(
      (response) => {
        setAutomatedCampaigns(response.result || []);
        setIsLoadingAutomatedCampaigns(false);
      },
      (error) => {
        console.error('Error fetching automated campaigns:', error);
        setAutomatedCampaigns([]);
        setIsLoadingAutomatedCampaigns(false);
      }
    );
  }, [getAutomatedCampaigns]);

  useEffect(() => {
    setAutomatedPageIndex(1);
    setCampaignsPageIndex(1);
  }, [searchKey]);

  const handleAutomationChange = (enabled) => {
    if (enabled) {
      setShowAutomationModal(true);
    } else {
      setAutomationEnabled(false);
      change("automationEnabled", false);
    }
  };

  const handleModalClose = () => {
    setShowAutomationModal(false);
    setAutomationEnabled(false);
    change("automationEnabled", false);
  };

  const handleActivateAutomation = () => {
    setAutomationEnabled(true);
    change("automationEnabled", true);
    setShowAutomationModal(false);
  };

  const onTabChange = (nav, index) => {
    setCurrentTab(index);
  };

  const currentTemplates = mockData[navData[currentTab].label] || [];

  const handleAutomatedCampaignCreated = (success) => {
    setShowAutomationModal(false);
    setAutomatedCampaignToEdit(null);
    if (success) {
      // Refresh the automated campaigns list
      setIsLoadingAutomatedCampaigns(true);
      getAutomatedCampaigns(
        (response) => {
          setAutomatedCampaigns(response.result || []);
          setIsLoadingAutomatedCampaigns(false);
        },
        (error) => {
          console.error('Error refreshing automated campaigns:', error);
          setIsLoadingAutomatedCampaigns(false);
        }
      );
      successMessage(automatedCampaignToEdit ? "Campaign updated successfully" : "Campaign created successfully");
    }
  };

  // Handle campaign deletion
  const handleDeleteCampaign = (campaign) => {
    setCampaignToDelete(campaign);
    setShowDeleteConfirmModal(true);
  };

  const confirmDeleteCampaign = () => {
    if (!campaignToDelete || isDeleteCampaignLoading) return;

    setIsDeleteCampaignLoading(true);
    deleteCampaign(
      campaignToDelete.campaignId,
      (response) => {
        // Success handler
        successMessage("Campaign deleted successfully");
        setShowDeleteConfirmModal(false);
        setCampaignToDelete(null);
        setIsDeleteCampaignLoading(false);

        // Refresh the campaigns list
        const typeMap = {
          0: 1, // Pre-sale
          1: 2, // Re-engage
          2: 3  // Closer
        };
        const campaignType = typeMap[currentTab];

        getCampaignsByType(
          campaignType,
          (response) => {
            setCampaigns(response.result || []);
          },
          (error) => {
            console.error('Error refreshing campaigns:', error);
          }
        );
      },
      (error) => {
        // Error handler
        console.error('Error deleting campaign:', error);
        // Check if the error is because the campaign is in use
        if (error && error.error?.message && error.error.message.includes("CampaignInUse")) {
          errorMessage("This campaign is currently in use and cannot be deleted.");
        } else {
          errorMessage("Failed to delete campaign. Please try again.");
        }
        setShowDeleteConfirmModal(false);
        setCampaignToDelete(null);
        setIsDeleteCampaignLoading(false);
      }
    );
  };

  const cancelDeleteCampaign = () => {
    setShowDeleteConfirmModal(false);
    setCampaignToDelete(null);
    setIsDeleteCampaignLoading(false);
  };

  // Handle automated campaign deletion
  const handleDeleteAutomatedCampaign = (campaign) => {
    setAutomatedCampaignToDelete(campaign);
    setShowAutomatedDeleteConfirmModal(true);
  };

  const confirmDeleteAutomatedCampaign = () => {
    if (!automatedCampaignToDelete || isDeleteAutomatedCampaignLoading) return;

    setIsDeleteAutomatedCampaignLoading(true);
    deleteAutomatedCampaign(
      automatedCampaignToDelete.automatedCampaignId,
      (response) => {
        // Success handler
        successMessage("Automated campaign deleted successfully");
        setShowAutomatedDeleteConfirmModal(false);
        setAutomatedCampaignToDelete(null);
        setIsDeleteAutomatedCampaignLoading(false);

        // Refresh the automated campaigns list
        setIsLoadingAutomatedCampaigns(true);
        getAutomatedCampaigns(
          (response) => {
            setAutomatedCampaigns(response.result || []);
            setIsLoadingAutomatedCampaigns(false);
          },
          (error) => {
            console.error('Error refreshing automated campaigns:', error);
            setIsLoadingAutomatedCampaigns(false);
          }
        );
      },
      (error) => {
        // Error handler
        console.error('Error deleting automated campaign:', error);
        // Check if the error is because the campaign is in use
        if (error && error.error?.message && error.error.message.includes("CampaignInUse")) {
          errorMessage("This automated campaign is currently in use and cannot be deleted.");
        } else {
          errorMessage("Failed to delete automated campaign. Please try again.");
        }
        setShowAutomatedDeleteConfirmModal(false);
        setAutomatedCampaignToDelete(null);
        setIsDeleteAutomatedCampaignLoading(false);
      }
    );
  };

  const cancelDeleteAutomatedCampaign = () => {
    setShowAutomatedDeleteConfirmModal(false);
    setAutomatedCampaignToDelete(null);
    setIsDeleteAutomatedCampaignLoading(false);
  };

  // Filter automated campaigns based on search key
  const filteredAutomatedCampaigns = searchKey
    ? automatedCampaigns.filter(campaign =>
      campaign.name.toLowerCase().includes(searchKey.toLowerCase()) ||
      campaign.bookerName?.toLowerCase().includes(searchKey.toLowerCase()) ||
      campaign.salesPersonFullName?.toLowerCase().includes(searchKey.toLowerCase()) ||
      campaign.preSaleCampaignName?.toLowerCase().includes(searchKey.toLowerCase()) ||
      campaign.reEngageCampaignName?.toLowerCase().includes(searchKey.toLowerCase()) ||
      campaign.closerCampaignName?.toLowerCase().includes(searchKey.toLowerCase())
    )
    : automatedCampaigns;

  // Filter regular campaigns based on search key
  const filteredCampaigns = searchKey
    ? campaigns.filter(campaign =>
      campaign.templateName.toLowerCase().includes(searchKey.toLowerCase())
    )
    : campaigns;

  // Handle edit automated campaign
  const handleEditAutomatedCampaign = (campaign) => {
    setAutomatedCampaignToEdit(campaign);
    setShowAutomationModal(true);
  };

  // Handle create new automated campaign
  const handleCreateAutomatedCampaign = () => {
    setAutomatedCampaignToEdit(null);
    setShowAutomationModal(true);
  };

  return (
    <section className="moovsoon-page-container campaigns-page">
      <div className="moovsoon-page-body automated-campaigns">
        <div className="d-flex align-items-center mb-3">
          <h2 className="mr-2 mb-0">Automated Campaigns</h2>
          <Tooltip
            placement="right"
            message="When enabled, this feature automatically sends selected email campaigns to all qualified leads based on their status (Booked Survey, Pending, or Attempted Contact/Ghosted). Leads will receive relevant messages and touches at the scheduled intervals without requiring manual setup for each contact."
            component={
              <i
                className="icon icon-info text-gray automated-help"
                style={{ cursor: "pointer" }}
              />
            }
          />
        </div>

        <div className="d-flex align-items-center mb-3">
          <div className="flex-fill form-group mb-0">
            {/* <SearchField
              initialValue={searchKey}
              onSearch={setSearchKey}
              className="flex-fill form-group mb-0"
              placeholder="Enter your query"
            /> */}
          </div>
          <button
            type="button"
            className="btn btn-primary btn-md ml-3"
            onClick={handleCreateAutomatedCampaign}>
            Create Automated Campaigns
          </button>
        </div>

        <div className="automated-campaign-table">
          {isLoadingAutomatedCampaigns ? (
            <TableLoader />
          ) : filteredAutomatedCampaigns.length > 0 ? (
            <>
              <Table
                columns={automatedColumns(history, handleDeleteAutomatedCampaign, handleEditAutomatedCampaign)}
                data={paginateData(filteredAutomatedCampaigns, automatedPageIndex, pageSize)}
                onSort={() => { }}
              />
              <Pagination
                total={filteredAutomatedCampaigns.length}
                count={pageSize}
                page={automatedPageIndex}
                onChange={setAutomatedPageIndex}
              />
            </>
          ) : (
            <div className="text-center py-5">
              <i className="icon icon-inbox mb-3 d-block text-primary" style={{ fontSize: '48px' }} />
              <h4>No Automated Campaigns Found</h4>
              <p className="text-muted">
                There are no automated campaigns available. Click "Create Automated Campaigns" to add one.
              </p>
            </div>
          )}
        </div>
      </div>
      <div className="moovsoon-page-body">
        <div className="d-flex align-items-center mb-3">
          <h2 className="mr-3 mb-0">Campaigns</h2>
        </div>
        <div className="d-flex align-items-center mb-3">
          <div className="d-flex">
            <NavTabs data={navData} onSelect={onTabChange} />
          </div>
          <div className="flex-fill form-group mb-0">
            {/* <SearchField
            initialValue={searchKey}
            onSearch={setSearchKey}
            className="ml-3 flex-fill form-group mb-0"
            placeholder="Enter your query"
          /> */}
          </div>
          <button
            type="button"
            className="btn btn-primary btn-md ml-3"
            onClick={() => history.push("/campaigns/create")}>
            Create Campaign
          </button>
        </div>

        <div className="campaign-table">
          {isLoadingCampaigns ? (
            <TableLoader />
          ) : filteredCampaigns.length > 0 ? (
            <>
              <Table
                columns={getCampaignColumns(history, handleDeleteCampaign)}
                data={paginateData(filteredCampaigns, campaignsPageIndex, pageSize)}
                onSort={() => { }}
              />
              <Pagination
                total={filteredCampaigns.length}
                count={pageSize}
                page={campaignsPageIndex}
                onChange={setCampaignsPageIndex}
              />
            </>
          ) : (
            <div className="text-center py-5">
              <i className="icon icon-inbox mb-3 d-block text-primary" style={{ fontSize: '48px' }} />
              <h4>No Campaigns Found</h4>
              <p className="text-muted">
                There are no campaigns available for this type. Click "Create Campaign" to add one.
              </p>
            </div>
          )}
        </div>
      </div>

      {/* Create Automated Campaign Modal */}
      <InfoModal
        show={showAutomationModal}
        title={automatedCampaignToEdit ? "Edit Automated Campaign" : "Create Automated Campaign"}
        className="campaign-activation-modal"
        onYes={handleModalClose}
        message={
          <CreateAutomatedCampaignModal
            show={showAutomationModal}
            onClose={handleAutomatedCampaignCreated}
            automatedCampaignId={automatedCampaignToEdit?.automatedCampaignId}
            isEditMode={!!automatedCampaignToEdit}
          />
        }
        footer={<></>}
      />

      {/* Delete Campaign Confirmation Modal */}
      <InfoModal
        show={showDeleteConfirmModal}
        title="Delete Campaign"
        className="delete-confirmation-modal"
        onYes={cancelDeleteCampaign}
        message={
          <div >
            <i className="icon icon-warning text-warning mb-3" style={{ fontSize: '36px' }} />
            <h5>Delete "{campaignToDelete?.templateName}"?</h5>
            <p className="text-muted mt-2">
              This action cannot be undone and will permanently remove this campaign.
            </p>
          </div>
        }
        footer={
          <div className="d-flex justify-content-end">
            <button
              type="button"
              className="btn btn-default mr-2"
              onClick={cancelDeleteCampaign}
              disabled={isDeleteCampaignLoading}>
              Cancel
            </button>
            <button
              type="button"
              className="btn btn-danger"
              onClick={confirmDeleteCampaign}
              disabled={isDeleteCampaignLoading}>
              {isDeleteCampaignLoading ? "Deleting..." : "Delete"}
            </button>
          </div>
        }
      />

      {/* Delete Automated Campaign Confirmation Modal */}
      <InfoModal
        show={showAutomatedDeleteConfirmModal}
        title="Delete Automated Campaign"
        className="delete-confirmation-modal"
        onYes={cancelDeleteAutomatedCampaign}
        message={
          <div >
            <i className="icon icon-warning text-warning mb-3" style={{ fontSize: '36px' }} />
            <h5>Delete "{automatedCampaignToDelete?.name}"?</h5>
            <p className="text-muted mt-2">
              This action cannot be undone and will permanently remove this automated campaign.
            </p>
          </div>
        }
        footer={
          <div className="d-flex justify-content-end">
            <button
              type="button"
              className="btn btn-default mr-2"
              onClick={cancelDeleteAutomatedCampaign}
              disabled={isDeleteAutomatedCampaignLoading}>
              Cancel
            </button>
            <button
              type="button"
              className="btn btn-danger"
              onClick={confirmDeleteAutomatedCampaign}
              disabled={isDeleteAutomatedCampaignLoading}>
              {isDeleteAutomatedCampaignLoading ? "Deleting..." : "Delete"}
            </button>
          </div>
        }
      />
    </section>
  );
};

const mapDispatchToProps = {
  getCampaignsByType,
  deleteCampaign,
  getAutomatedCampaigns,
  deleteAutomatedCampaign
};

export default connect(null, mapDispatchToProps)(reduxForm({
  form: "campaignsForm",
  enableReinitialize: true,
})(Campaigns));
