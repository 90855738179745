import fetchHandler from "../../utils/fetchHandler";
import { MOVESCOUT } from "../types";

export const getLeadsOpportunities = (values, successHandler, errorHandler) => {
  const fetchOptions = {
    url: `leads/get-leads-opportunities`,
    method: "POST",
    secure: true,
    actionType: MOVESCOUT.GET_LEADS_OPPORTUNITIES,
    body: JSON.stringify(values),
  };
  return fetchHandler(fetchOptions, successHandler, errorHandler);
};



export const getLeadPropertyMatch = (values, successHandler, errorHandler) => {
  const fetchOptions = {
    url: `leads/get-lead-property-match`,
    method: "POST",
    secure: true,
    actionType: MOVESCOUT.GET_LEAD_PROPERTY_MATCH,
    body: JSON.stringify(values),
  };
  return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const getLeadOpportunityDetailsByLeadId = (
  values,
  successHandler,
  errorHandler
) => {
  const fetchOptions = {
    url: `leads/get-opportunity-details?opportunityId=${values.leadId}`,
    method: "GET",
    secure: true,
    actionType: MOVESCOUT.GET_LEAD_OPPORTUNITY_DETAILS_BY_LEAD_ID,
  };
  return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const setLeadsOpportunitiesFilter = (filterObject) => {
  return {
    type: MOVESCOUT.SET_LEADS_OPPORTUNITIES_FILTER,
    payload: filterObject,
  };
};

export const setLeadOpportunityDetailsLoadingState = (loadingState) => {
  return {
    type: MOVESCOUT.SET_LEAD_OPPORTUNITY_DETAILS_LOADING_STATE,
    payload: loadingState,
  };
};

export const resetLeadOpportunityDetailsLoadingState = () => {
  return {
    type: MOVESCOUT.RESET_LEAD_OPPORTUNITY_DETAILS_LOADING_STATE,
  };
};

export const setLeadPropertyMatchLoadingState = (loadingState) => {
  return {
    type: MOVESCOUT.SET_LEAD_PROPERTY_MATCH_LOADING_STATE,
    payload: loadingState,
  };
};

export const resetLeadPropertyMatchLoadingState = () => {
  return {
    type: MOVESCOUT.RESET_LEAD_PROPERTY_MATCH_LOADING_STATE,
  };
};
