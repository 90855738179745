import React from "react";
import { CAMPAIGN_TYPES, TIMING_OPTIONS, TEMPLATE_TYPE_OPTIONS, CAMPAIGN_EVENT_OPTIONS } from "../../../constant/movscoutCampaignManagement"

export const campaignTypes = [
  {
    type: CAMPAIGN_TYPES.PRE_SALE.value,
    title: CAMPAIGN_TYPES.PRE_SALE.label,
    description:
      "Start engaging customers before they hit your main sales funnel.",
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round">
        <path d="m3 11 18-5v12L3 14v-3z" />
        <path d="M11.6 16.8a3 3 0 1 1-5.8-1.6" />
      </svg>
    ),
  },
  {
    type: CAMPAIGN_TYPES.RE_ENGAGE.value,
    title: CAMPAIGN_TYPES.RE_ENGAGE.label,
    description:
      "Bring back customers who have dropped off with targeted messaging.",
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round">
        <path d="M21 12a9 9 0 0 0-9-9 9.75 9.75 0 0 0-6.74 2.74L3 8" />
        <path d="M3 3v5h5" />
        <path d="M3 12a9 9 0 0 0 9 9 9.75 9.75 0 0 0 6.74-2.74L21 16" />
        <path d="M16 16h5v5" />
      </svg>
    ),
  },
  {
    type: CAMPAIGN_TYPES.CLOSER.value,
    title: CAMPAIGN_TYPES.CLOSER.label,
    description:
      "Seal the deal with campaigns designed to close the sales loop.",
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round">
        <path d="m11 17 2 2a1 1 0 1 0 3-3" />
        <path d="m14 14 2.5 2.5a1 1 0 1 0 3-3l-3.88-3.88a3 3 0 0 0-4.24 0l-.88.88a1 1 0 1 1-3-3l2.81-2.81a5.79 5.79 0 0 1 7.06-.87l.47.28a2 2 0 0 0 1.42.25L21 4" />
        <path d="m21 3 1 11h-2" />
        <path d="M3 3 2 14l6.5 6.5a1 1 0 1 0 3-3" />
        <path d="M3 4h8" />
      </svg>
    ),
  },
];

export const timingOptions = Object.values(TIMING_OPTIONS)
export const campaignEventOptions = Object.values(CAMPAIGN_EVENT_OPTIONS)
export const templateTypeOptions = Object.values(TEMPLATE_TYPE_OPTIONS)



export const CampaignType = ({ title, description, icon, selected, onClick }) => (
  <div
    className={`campaign-type-card ${selected ? "selected" : ""}`}
    onClick={onClick}>
    <div className="campaign-type-icon">{icon}</div>
    <h3>{title}</h3>
    <p>{description}</p>
  </div>
);



export const TouchPoint = ({
  index,
  onDelete,
  onPreview,
  touchpoint,
  onChange,
  hasError,
  availableTemplates = []
}) => (
  <div className={`touchpoint-row ${hasError ? 'border-danger' : ''}`}>
    <div className="touchpoint-number">{index + 1}</div>
    <div className="touchpoint-field">
      <select
        name="timing"
        className="form-control select-control"
        value={touchpoint.timing || ""}
        onChange={(e) => onChange(index, "timing", e.target.value)}>
        <option value="">Select Timing</option>
        {timingOptions.map(option => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
    </div>
    <div className="touchpoint-field">
      <select
        name="event"
        className="form-control select-control"
        value={touchpoint.event || ""}
        onChange={(e) => onChange(index, "event", e.target.value)}>
        <option value="">Select Event</option>
        {campaignEventOptions.map(option => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
    </div>
    <div className="touchpoint-field">
      <select
        name="type"
        className="form-control select-control"
        value={touchpoint.type || ""}
        onChange={(e) => onChange(index, "type", e.target.value)}>
        <option value="">Select Type</option>
        {templateTypeOptions.map(option => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
    </div>
    <div className="touchpoint-field template-field">
      <select
        name="template"
        className={`form-control select-control ${!touchpoint.type ? 'disabled-select' : availableTemplates.length === 0 ? 'empty-select' : ''}`}
        value={touchpoint.template || ""}
        onChange={(e) => onChange(index, "template", e.target.value)}
        disabled={!touchpoint.type || availableTemplates.length === 0}>
        <option value="">
          {!touchpoint.type
            ? "Select a type first"
            : availableTemplates.length === 0
              ? "No templates available"
              : "Select Template"}
        </option>

        {/* Display templates filtered by type */}
        {availableTemplates.map(template => (
          <option key={template.templateId} value={template.templateId}>
            {template.templateName}
          </option>
        ))}
      </select>

      {/* Helper text for template selection */}
      {!touchpoint.type && (
        <div className="helper-text">Please select a template type first</div>
      )}

      {touchpoint.type && availableTemplates.length === 0 && (
        <div className="helper-text error-text">No templates available for this type</div>
      )}
    </div>
    <div className="touchpoint-actions">
      <button
        type="button"
        className="btn btn-link text-danger"
        onClick={onDelete}>
        <i className="icon icon-trash" />
      </button>
    </div>
  </div>
);

const ReviewCampaign = ({ touchpoints }) => (
  <div className="review-section">
    <h2>Review Your Campaign</h2>
    <div className="review-list">
      {touchpoints.map((touchpoint, index) => {
        // Get the display values from the touchpoint data
        const timing = touchpoint.timing?.replace(/_/g, " ") || "Not set";
        const event = touchpoint.event?.replace(/_/g, " ") || "Not set";
        const type = touchpoint.type || "Not set";
        const template = touchpoint.template?.replace(/_/g, " ") || "Not set";

        return (
          <div key={index} className="review-item">
            <div className="review-content">
              <span className="review-label">Touchpoint {index + 1}:</span>
              <span className="review-text">
                {timing}, {event} via {type} using "{template}"
              </span>
            </div>
          </div>
        );
      })}
    </div>
  </div>
);