import { get } from "lodash";
import queryString from "query-string";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  getLeadOpportunityDetailsByLeadId,
  getRealtorCommunicationFeed,
} from "../../../../store/actions";
import {
  getLeadPropertyMatch,
  resetLeadOpportunityDetailsLoadingState,
  resetLeadPropertyMatchLoadingState,
  setLeadOpportunityDetailsLoadingState,
  setLeadPropertyMatchLoadingState,
} from "../../../../store/actions/movescoutLeadsAction";
import InfoModal from "../../../../utils/infoModal";
import NavTabs from "../../../../utils/navTabs";
import Button from "../../../../utils/uiElements";
import ResizableGoogleStreetView from "../../../organic/ResizableGoogleStreetView";
import CommunicationFeed from "../../../realtors/view/communicationFeed";
import "./leadDetails.scss";
import { LeadDetailsCard } from "./leadDetailsCard";
import LeadsOwnerBlock from "./LeadsOwnerBlock";
import LeadsRealtorBlock from "./LeadsRealtorBlock";
import SidePanel from "../../../../utils/sidePanel";
import CampaignPanel from "./CampaignPanel";
import Tooltip from "../../../../utils/tooltip";
import { mockData } from '../../../campaigns';

const presaleCampaigns = [
  { id: '1', name: "Welcome Series", lastModified: "03/15/2025", status: "Active", company: "MoveScout" },
  { id: '2', name: "Initial Contact", lastModified: "03/10/2025", status: "Active", company: "MoveScout" }
];

const reengageCampaigns = [
  { id: '3', name: "Follow-up Series", lastModified: "03/08/2025", status: "Active", company: "MoveScout" },
  { id: '4', name: "Reminder Campaign", lastModified: "03/01/2025", status: "Inactive", company: "MoveScout" }
];

const closerCampaigns = [
  { id: '5', name: "Final Offer", lastModified: "03/12/2025", status: "Active", company: "MoveScout" },
  { id: '6', name: "Deal Closer", lastModified: "03/05/2025", status: "Inactive", company: "MoveScout" }
];

export const navData = [
  {
    label: "Email",
    icon: "icon icon-inbox",
  },
  {
    label: "SMS",
    icon: "icon icon-sms",
  },
  {
    label: "Mailer",
    icon: "icon icon-wallet",
  },
];



const MovescoutLeadDetails = ({
  history,
  match,
  location,
  leadOpportunityDetails,
  getLeadOpportunityDetailsByLeadId,
  loadingState,
  resetLeadOpportunityDetailsLoadingState,
  setLeadOpportunityDetailsLoadingState,
  leadPropertyMatch,
  getLeadPropertyMatch,
  resetLeadPropertyMatchLoadingState,
  setLeadPropertyMatchLoadingState,
  getRealtorCommunicationFeed,
  dispatch,
}) => {
  const [activeTab, setActiveTab] = useState("Email");
  const [activeCampaign, setActiveCampaign] = useState(null);
  const [startDate, setStartDate] = useState("");
  const [selectedCampaign, setSelectedCampaign] = useState("");

  const [showGoogleStreetView, setShowGoogleStreetView] = useState(false);
  const [showStreetAddress, setShowStreetAddress] = useState("");

  const [communicationFeedType, setCommunicationFeedType] = useState(0);

  const [showCampaignPanel, setShowCampaignPanel] = useState(false);

  const [activeCampaigns, setActiveCampaigns] = useState({
    presale: mockData["Pre-sale"][0],
    reengage: mockData["Re-engage"][0],
    closer: mockData["Closer"][0]
  });

  const [automationEnabled, setAutomationEnabled] = useState(true);


  const handleSetCommunicationFeedType = (commType) => {
    setCommunicationFeedType((type) => (type === commType ? 0 : commType));
  };

  const handleAutomationChange = (enabled) => {
    setAutomationEnabled(enabled);

    if (enabled) {
      // Set default campaigns using mockData
      const defaultCampaigns = {
        presale: mockData["Pre-sale"][0],
        reengage: mockData["Re-engage"][0],
        closer: mockData["Closer"][0]
      };

      setActiveCampaigns(prevCampaigns => ({
        ...prevCampaigns,
        ...defaultCampaigns
      }));
    } else {
      setActiveCampaigns({
        presale: null,
        reengage: null,
        closer: null
      });
    }
  };

  const { params } = match;

  // Get the previous search query if it exists
  const prevSearch = queryString.parse(location.search).from;

  useEffect(() => {
    let getLeadOpportunityDetailsController = null;

    if (params?.id) {
      if (params.id === leadOpportunityDetails?.opportunityId) {
        setLeadOpportunityDetailsLoadingState(false);
        setLeadPropertyMatchLoadingState(false);
        return;
      }

      getLeadOpportunityDetailsController = getLeadOpportunityDetailsByLeadId(
        { leadId: params.id },
        null,
        null
      );
    }
    return () => {
      if (getLeadOpportunityDetailsController?.abort) {
        getLeadOpportunityDetailsController.abort();
      }
    };
  }, []);

  useEffect(() => {
    if (leadOpportunityDetails) {
      let getLeadPropertyMatchController = null;

      const parsedLeadOpportunityDetails = JSON.parse(
        leadOpportunityDetails.payload
      );
      const { OriginAddress, OriginCity, OriginState, OriginZip } =
        parsedLeadOpportunityDetails;

      const address = `${OriginAddress}`;

      getLeadPropertyMatchController = getLeadPropertyMatch(
        {
          address,
        },
        null,
        null
      );

      return () => {
        if (getLeadPropertyMatchController?.abort) {
          getLeadPropertyMatchController.abort();
        }
      };
    }
  }, [leadOpportunityDetails]);

  useEffect(() => {
    return () => {
      resetLeadOpportunityDetailsLoadingState();
      resetLeadPropertyMatchLoadingState();
    };
  }, []);

  const getTouchesData = () => {
    // Get lead status from leadOpportunityDetails
    const leadStatus = leadOpportunityDetails?.disposition || "";

    // If status is not Pending or Attempted Contact, return empty array
    if (!['Pending', 'Attempted Contact'].includes(leadStatus)) {
      return [];
    }

    const today = new Date();
    const touches = [];

    if (leadStatus === 'Pending' && activeCampaigns.closer) {
      // Use Closer Campaign touchpoints
      const closerTouchpoints = mockData["Closer"][0].touchpoints;

      closerTouchpoints.forEach(touchpoint => {
        const [days, timeframe] = touchpoint.timing.split('_');
        const daysNum = parseInt(days);

        const touchDate = new Date(today);
        touchDate.setDate(touchDate.getDate() - daysNum);

        touches.push({
          date: touchDate.toLocaleDateString('en-US', { month: 'short', day: 'numeric', year: 'numeric' }),
          touches: [{
            id: Math.random().toString(36).substr(2, 9),
            type: touchpoint.type,
            status: "Delivered",
            description: `Quote Conversion Track: ${touchpoint.template.replace(/_/g, ' ')}`,
            timeAgo: `${daysNum}d ago`,
            icon: touchpoint.type === 'email' ? 'icon-inbox' :
              touchpoint.type === 'sms' ? 'icon-sms' : 'icon-wallet'
          }]
        });
      });
    }

    if (leadStatus === 'Attempted Contact' && activeCampaigns.reengage) {
      // Use Re-engage Campaign touchpoints
      const reengageTouchpoints = mockData["Re-engage"][0].touchpoints;

      reengageTouchpoints.forEach(touchpoint => {
        const [days, timeframe] = touchpoint.timing.split('_');
        const daysNum = parseInt(days);

        const touchDate = new Date(today);
        touchDate.setDate(touchDate.getDate() - daysNum);

        touches.push({
          date: touchDate.toLocaleDateString('en-US', { month: 'short', day: 'numeric', year: 'numeric' }),
          touches: [{
            id: Math.random().toString(36).substr(2, 9),
            type: touchpoint.type,
            status: "Delivered",
            description: `Lead Revival Series: ${touchpoint.template.replace(/_/g, ' ')}`,
            timeAgo: `${daysNum}d ago`,
            icon: touchpoint.type === 'email' ? 'icon-inbox' :
              touchpoint.type === 'sms' ? 'icon-sms' : 'icon-wallet'
          }]
        });
      });
    }

    // Sort touches by date (most recent first)
    return touches.sort((a, b) => new Date(b.date) - new Date(a.date));
  };

  const onTabSelect = (tab) => {
    setActiveTab(tab.label);
  };

  const renderTouchesContent = () => {
    const touches = getTouchesData();

    return touches.map((dateGroup) => (
      <div key={dateGroup.date} className="touches-date-group">
        <div className="touches-date-header">
          <h3>{dateGroup.date}</h3>
        </div>
        <div className="touches-list">
          {dateGroup.touches.map((touch) => (
            <div key={touch.id} className="touches-card">
              <div className="touches-card-content">
                <div className="touches-card-icon">
                  <i className={`icon ${touch.icon}`} />
                </div>
                <div className="touches-card-details">
                  <p className="status">{touch.status}</p>
                  <p className="description">{touch.description}</p>
                </div>
                <div className="touches-card-date">{touch.timeAgo}</div>
              </div>
            </div>
          ))}
        </div>
      </div>
    ));
  };

  const handleSaveCampaign = (values) => {
    const newActiveCampaigns = {};

    // Map campaign IDs to their full details
    if (values.campaign_presale) {
      const campaign = presaleCampaigns.find(c => c.id === values.campaign_presale.value);
      if (campaign) {
        newActiveCampaigns.presale = {
          ...campaign,
          startDate: new Date().toISOString().split('T')[0]
        };
      }
    }

    if (values.campaign_reengage) {
      const campaign = reengageCampaigns.find(c => c.id === values.campaign_reengage.value);
      if (campaign) {
        newActiveCampaigns.reengage = {
          ...campaign,
          startDate: new Date().toISOString().split('T')[0]
        };
      }
    }

    if (values.campaign_closer) {
      const campaign = closerCampaigns.find(c => c.id === values.campaign_closer.value);
      if (campaign) {
        newActiveCampaigns.closer = {
          ...campaign,
          startDate: new Date().toISOString().split('T')[0]
        };
      }
    }




    setActiveCampaigns(prevCampaigns => ({
      ...newActiveCampaigns
    }));

    setShowCampaignPanel(false);
  };

  const handleBack = () => {
    const backUrl = prevSearch
      ? `/plugins/movescout/leads?search=${prevSearch}`
      : "/plugins/movescout/leads";

    history.push(backUrl, { fromLeadDetails: true });
  };


  console.log("loadingState?.leadPropertyMatch", loadingState?.leadPropertyMatch)

  return (
    <section className="moovsoon-page-container">
      <div className="d-flex justify-content-between align-items-center mb-3">
        <div className="d-flex align-items-center">
          <Button
            type="button"
            value="< Back"
            className="btn btn-default btn-sm"
            onClick={handleBack}
          />
        </div>
        <button
          className="btn btn-primary btn-md"
          onClick={() => setShowCampaignPanel(true)}>
          Add Campaigns
        </button>
      </div>

      <div className="lead-details-container">
        <LeadDetailsCard
          isLeadDetailsLoading={loadingState?.leadOpportunityDetails}
          leadOpportunityDetails={leadOpportunityDetails}
          onShowStreetView={() => setShowGoogleStreetView(true)}
          onSetStreetAddress={setShowStreetAddress}
          activeCampaigns={activeCampaigns}
          setActiveCampaigns={setActiveCampaigns}
          automationEnabled={automationEnabled}
          onAutomationChange={handleAutomationChange}
        />

        <div className="lead-details-property-container">
          <div className="property-header">
            <h2 className="mr-2 flex-fill">Matched Property</h2>
            {leadPropertyMatch?.propertyId &&
              !loadingState?.leadPropertyMatch && (
                <>
                  {leadPropertyMatch?.similarityPercentage && (
                    <div className="similarity-badge">
                      {Math.round(leadPropertyMatch.similarityPercentage * 100)}
                      % match
                    </div>
                  )}
                  <button
                    className="btn btn-primary btn-md"
                    onClick={() =>
                      window.open(
                        `/listings/${leadPropertyMatch?.propertyId}/view`,
                        "_blank"
                      )
                    }>
                    View Property
                  </button>
                </>
              )}
          </div>
          {!leadPropertyMatch && !loadingState?.leadPropertyMatch ? (
            <div className="mt-3 alert alert-info p-2 text-center">
              No match found
            </div>
          ) : (
            <>
              <div className="card card-primary p-4 bt-2">
                <div className="row d-flex flex-row">
                  <div className="w-50">
                    <LeadsRealtorBlock
                      {...{
                        propertyDetails: leadPropertyMatch,
                        setCommunicationFeedType: (type) =>
                          handleSetCommunicationFeedType(type),
                        history,
                        match,
                        isPropertyDetailsLoading:
                          loadingState?.leadPropertyMatch,
                      }}
                    />
                  </div>

                  <div className="w-50 pl-2">
                    <LeadsOwnerBlock
                      {...{
                        propertyOwnerInfo: {
                          firstName:
                            leadPropertyMatch?.propertyOwnerFirstName,
                          name: leadPropertyMatch?.propertyOwnerName,
                          email: leadPropertyMatch?.propertyOwnerEmail,
                          address: leadPropertyMatch?.propertyOwnerAddress,
                          phone: leadPropertyMatch?.propertyOwnerPhone,
                        },
                        setCommunicationFeedType: (type) =>
                          handleSetCommunicationFeedType(type),
                        isPropertyOwnerInfoLoading:
                          loadingState?.leadPropertyMatch,
                      }}
                    />
                  </div>
                </div>
              </div>
              {communicationFeedType === 1 || communicationFeedType === 2 ? (
                <div className="mt-3">
                  <CommunicationFeed
                    getCommunicationFeed={getRealtorCommunicationFeed}
                    realtor={{
                      realtorId: leadPropertyMatch.realtorId,
                    }}
                    propertyOwnerId={leadPropertyMatch.propertyOwnerId}
                    isPropertyPage={1}
                    communicationFeedType={communicationFeedType}
                    propertyId={leadPropertyMatch.propertyId}
                  />
                </div>
              ) : null}
            </>
          )}
        </div>
        {
          (activeCampaigns.closer || activeCampaigns.presale || activeCampaigns.reengage) &&
          <div className="lead-details-touches-container">
            <h2 className="mr-2 flex-fill">Campaign Touches</h2>
            <div className="touches-section">
              <div className="touches-content">{renderTouchesContent()}</div>
            </div>
          </div>

        }
      </div>

      <ResizableGoogleStreetView
        address={showStreetAddress}
        show={showGoogleStreetView}
        onClose={() => setShowGoogleStreetView(false)}
      />

      <SidePanel
        show={showCampaignPanel}
        onHide={() => setShowCampaignPanel(false)}
        component={CampaignPanel}
        componentProps={{
          setShowCampaignPanel,
          campaigns: {
            "Pre-sale": presaleCampaigns,
            "Re-engage": reengageCampaigns,
            "Closer": closerCampaigns
          },
          parentDispatch: dispatch,
          onSave: handleSaveCampaign,
          initialValues: {
            campaign_presale: activeCampaigns?.presale?.id || "",
            campaign_reengage: activeCampaigns?.reengage?.id || "",
            campaign_closer: activeCampaigns?.closer?.id || ""
          }
        }}
      />
    </section>
  );
};

const mapStateToProps = (state) => ({
  leadOpportunityDetails: get(
    state,
    "MovescoutLeads.leadOpportunityDetails",
    null
  ),
  leadPropertyMatch: get(state, "MovescoutLeads.leadPropertyMatch", null),
  loadingState: get(state, "MovescoutLeads.loadingState", null),
});

const mapDispatchToProps = {
  getLeadOpportunityDetailsByLeadId,
  setLeadOpportunityDetailsLoadingState,
  resetLeadOpportunityDetailsLoadingState,
  getLeadPropertyMatch,
  resetLeadPropertyMatchLoadingState,
  setLeadPropertyMatchLoadingState,
  getRealtorCommunicationFeed,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MovescoutLeadDetails);
