import React from "react";
import { userTypes } from "../../constant/userManagement";

export const navigationLinks = ({ userData }) => {
  const loggedInAs = (type) => {
    return userData && userData.userType === userTypes[type];
  };

  return [
    {
      to: "/dashboard",
      label: "Dashboard",
      icon: "icon-dashboard",
    },
    {
      to: "/users",
      label: "Users",
      icon: "icon-user",
      hide: loggedInAs("BO"),
    },
    {
      to: "/markets",
      label: "Markets",
      icon: "icon-price-tag",
      hide: loggedInAs("BO"),
    },
    {
      to: "/reports",
      label: "Reports",
      icon: "icon-graph-up",
      hide: loggedInAs("AD"),
    },
    {
      to: "/realtor-reports",
      label: "Reports",
      icon: "icon-graph-up",
      hide: !loggedInAs("AD"),
    },
    {
      to: "/listings",
      label: "Listings",
      icon: "icon-home",
    },
    {
      to: "/realtors",
      label: "Realtors",
      icon: "icon-users-check",
    },
    {
      to: "/settings",
      label: "Settings",
      icon: "icon-settings",
    },
    {
      to: "/plugins/movescout/leads",
      label: "Movescout",
      iconSvg: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          className="lucide lucide-grid-2x2-plus">
          <path d="M12 3v17a1 1 0 0 1-1 1H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2v6a1 1 0 0 1-1 1H3" />
          <path d="M16 19h6" />
          <path d="M19 22v-6" />
        </svg>
      ),
      hide: userData && !userData.accessToMovescoutPlugin,
    },
    {
      to: "/campaigns",
      label: "Campaigns",
      iconSvg: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          className="lucide lucide-layers">
          <path d="M12.83 2.18a2 2 0 0 0-1.66 0L2.6 6.08a1 1 0 0 0 0 1.83l8.58 3.91a2 2 0 0 0 1.66 0l8.58-3.9a1 1 0 0 0 0-1.83z" />
          <path d="M2 12a1 1 0 0 0 .58.91l8.6 3.91a2 2 0 0 0 1.65 0l8.58-3.9A1 1 0 0 0 22 12" />
          <path d="M2 17a1 1 0 0 0 .58.91l8.6 3.91a2 2 0 0 0 1.65 0l8.58-3.9A1 1 0 0 0 22 17" />
        </svg>
      ),
      hide: userData && !userData.accessToMovescoutPlugin,
    },
    {
      to: "/templates",
      label: "Templates",
      icon: "icon-template",
    },
  ];
};
