import { get } from "lodash";
import queryString from "query-string";
import React, { useCallback, useEffect, useReducer, useState } from "react";
import { connect } from "react-redux";
import { change, getFormValues } from "redux-form";

import Preloader from "../../../utils/preloader";
import SidePanel from "../../../utils/sidePanel";
import { successMessage } from "../../../utils/successMessage";
import Tooltip from "../../../utils/tooltip";
import { Button } from "./../../../utils/uiElements";

import { outboundCommunicationType } from "../../../constant/templateManagement";
import { userTypes } from "../../../constant/userManagement";

import { getPropertyNotificationData, updateSettingsHelper } from "./helper";

import CommunicationFeed from "../../realtors/view/communicationFeed";
import AutomaticProspectingBlock from "./AutomaticProspectingBlock";
import EmailPanel from "./EmailPanel";
import MailerPanel from "./MailerPanel";
import OwnerBlock from "./OwnerBlock";
import PropertyInfoBlock from "./PropertyInfoBlock";
import RealtorBlock from "./RealtorBlock";
import SettingsBlock from "./SettingsBlock";
import SliderBlock from "./SliderBlock";
import SMSPanel from "./SMSPanel";
import TimeLine from "./TimeLine";
import TrackConversionPanel from "./TrackConversionPanel";

import {
  clearProperty,
  fetchPropertyOwnerInfo,
  getAllmarketTemplates,
  getMailerSizeOptions,
  getmarketTemplateProspectingEvents,
  getMoveTypeList,
  getProperty,
  getPropertyNotificationSetting,
  getPropertyOwnerInfo,
  getPropertySourceList,
  getRealtorCommunicationFeed,
  getTemplateCompanyDetails,
  getTrackConversion,
  savePropertyNotification,
  sendManualProspecting,
  setOwnerInfoRequests,
  setPersistFilter,
  toggleMainLoader,
  updatePropertySettings,
  updateTrackConversion,
} from "../../../store/actions";

import { resetLoadingStates } from "../../../store/actions/propertyActions";
import { errorMessage } from "../../../utils/errorMessage";
import "../properties.scss";

import ResizableGoogleStreetView from "../../organic/ResizableGoogleStreetView";

const reducer = (state, action) => {
  return { ...state, ...action };
};

const initialStates = {
  dataLoaded: false,
  propertySettings: {},
  selectedID: null,
  ownerTemplateProspectingEvents: [],
  relatorTemplateProspectingEvents: [],
  templateList: [],
  mailerSizeOptions: [],
  templateSizeId: 1,
  propertyOwnerInfo: {},
  isPropertyOwnerInfoLoading: true,
};

const PropertyDetails = (props) => {
  const {
    history,
    location,
    match,
    getProperty,
    clearProperty,
    getmarketTemplateProspectingEvents,
    getAllmarketTemplates,
    getPropertyNotificationSetting,
    getPropertySourceList,
    getMoveTypeList,
    getPropertyOwnerInfo,
    getTemplateCompanyDetails,
    getTrackConversion,
    getRealtorCommunicationFeed,
    savePropertyNotification,
    updatePropertySettings,
    updateTrackConversion,
    fetchPropertyOwnerInfo,
    sendManualProspecting,
    trackConversionformValues,
    automaticProspectingformValues,
    emailTemplateformValues,
    mailerTemplateformValues,
    propertyDetails,
    userDetails,
    toggleMainLoader,
    setPersistFilter,
    getMailerSizeOptions,
    setOwnerInfoRequests,
    resetLoadingStates,
    loadingStates,
  } = props;

  const [state, dispatch] = useReducer(reducer, initialStates);

  const [trackConversionDisplay, showtrackConversion] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [dataReload, setDataReload] = useState(false);
  const [communicationFeedType, setCommunicationFeedType] = useState(false);
  const [trackConversionData, setTrackConversionData] = useState(false);
  const [confirmRequest, setConfirmRequest] = useState(false);
  const [updatePropertyInfo, setUpdatePropertyInfo] = useState(false);

  const [propertyNotificationSetting, setPropertyNotificationSetting] =
    useState({});

  const realtorId = queryString.parse(location.search).realtorId;

  const {
    dataLoaded,
    propertySettings,
    selectedID,
    ownerTemplateProspectingEvents,
    relatorTemplateProspectingEvents,
    templateList,
    propertySourceList,
    moveTypeList,
    propertyOwnerInfo,
    showMailPanel,
    showPostCardPanel,
    showSMSPanel,
    templateCompanyDetails,
    manualProspectingType,
    mailerSizeOptions,
    templateSizeId,
    isPropertyOwnerInfoLoading,
    showGoogleStreetView,
    showStreetAddress,
  } = state;

  const loggedInAs = useCallback(
    (type) => {
      return userDetails.userType === userTypes[type];
    },
    [userDetails]
  );

  useEffect(() => {
    clearProperty();
  }, []);

  useEffect(() => {
    let getPropertyController = null;
    if (userDetails && userDetails.userId) {
      let { params = null } = match;
      const successHandler = (e) => {
        dispatch({
          propertySettings: {
            markAsFavourite: e.result.markAsFavourite,
            markAsDiscarded: e.result.markAsDiscarded,
            markAsInProgress: e.result.markAsInProgress,
          },
        });
        dispatch({ selectedID: params.id });
      };

      if (params && params.id) {
        getPropertyController = getProperty(params.id, successHandler);
      }
    }
    return () => {
      if (getPropertyController?.abort) {
        getPropertyController.abort();
      }
    };
  }, [getProperty, userDetails, dataReload]);

  useEffect(() => {
    return () => {
      clearProperty();
    };
  }, []);

  //To get template size options for mailer
  useEffect(() => {
    const successHandler = (event) => {
      event && event.result && dispatch({ mailerSizeOptions: event.result });
    };
    const mailerController = getMailerSizeOptions(successHandler);

    return () => {
      if (mailerController?.abort) {
        mailerController.abort();
      }
    };
  }, [getMailerSizeOptions]);

  useEffect(() => {
    let sourceListController = null;
    if (typeof loggedInAs === "function" && !loggedInAs("AD")) {
      const successHandlerOwner = (event) => {
        dispatch({
          propertySourceList: event.result,
        });
      };
      sourceListController = getPropertySourceList(successHandlerOwner);
    }
    return () => {
      if (sourceListController?.abort) {
        sourceListController.abort();
      }
    };
  }, [getPropertySourceList, loggedInAs]);

  useEffect(() => {
    let ownerInfoController = null;
    if (selectedID) {
      const successHandlerOwner = (event) => {
        dispatch({
          propertyOwnerInfo: event.result,
          isPropertyOwnerInfoLoading: false,
        });

        dispatch({ dataLoaded: true });
      };

      ownerInfoController = getPropertyOwnerInfo(
        selectedID,
        successHandlerOwner
      );
    }
    return () => {
      if (ownerInfoController?.abort) {
        ownerInfoController.abort();
      }
    };
  }, [getPropertyOwnerInfo, loggedInAs, selectedID, updatePropertyInfo]);

  useEffect(() => {
    let moveTypeController = null;
    if (typeof loggedInAs === "function" && !loggedInAs("AD")) {
      const successHandlerOwner = (event) => {
        dispatch({
          moveTypeList: event.result,
        });
      };
      moveTypeController = getMoveTypeList(successHandlerOwner);
    }
    return () => {
      if (moveTypeController?.abort) {
        moveTypeController.abort();
      }
    };
  }, [getMoveTypeList, loggedInAs]);

  useEffect(() => {
    let trackConversionController = null;
    if (typeof loggedInAs === "function" && !loggedInAs("AD") && selectedID) {
      const successHandlerOwner = (event) => {
        setTrackConversionData(event.result);
      };
      trackConversionController = getTrackConversion(
        selectedID,
        successHandlerOwner
      );
    }
    return () => {
      if (trackConversionController?.abort) {
        trackConversionController.abort();
      }
    };
  }, [getTrackConversion, loggedInAs, selectedID]);

  useEffect(() => {
    let notificationController = null;
    if (
      userDetails &&
      userDetails.userId &&
      typeof loggedInAs === "function" &&
      !loggedInAs("AD")
    ) {
      const successHandler = (e) => {
        setPropertyNotificationSetting(e.result);
      };
      if (selectedID) {
        notificationController = getPropertyNotificationSetting(
          selectedID,
          successHandler,
          successHandler
        );
      }
    }
    return () => {
      if (notificationController?.abort) {
        notificationController.abort();
      }
    };
  }, [getPropertyNotificationSetting, selectedID, userDetails, loggedInAs]);

  useEffect(() => {
    let prospectingController = null;
    if (typeof loggedInAs === "function" && !loggedInAs("AD")) {
      const successHandlerOwner = (event) => {
        dispatch({
          ownerTemplateProspectingEvents: event.result,
        });
      };
      prospectingController = getmarketTemplateProspectingEvents(
        outboundCommunicationType.homeOwner.value,
        successHandlerOwner
      );
    }
    return () => {
      if (prospectingController?.abort) {
        prospectingController.abort();
      }
    };
  }, [getmarketTemplateProspectingEvents, loggedInAs]);

  useEffect(() => {
    let prospectingController = null;
    if (typeof loggedInAs === "function" && !loggedInAs("AD")) {
      const successHandlerRelator = (event) => {
        dispatch({
          relatorTemplateProspectingEvents: event.result,
        });
      };
      prospectingController = getmarketTemplateProspectingEvents(
        outboundCommunicationType.realtor.value,
        successHandlerRelator
      );
    }
    return () => {
      if (prospectingController?.abort) {
        prospectingController.abort();
      }
    };
  }, [getmarketTemplateProspectingEvents, loggedInAs]);

  useEffect(() => {
    let templateListController = null;
    if (typeof loggedInAs === "function" && !loggedInAs("AD")) {
      const successHandler = (event) => {
        dispatch({
          templateList: event.result,
        });
      };
      templateListController = getAllmarketTemplates(successHandler);
    }
    return () => {
      if (templateListController?.abort) {
        templateListController.abort();
      }
    };
  }, [getAllmarketTemplates, loggedInAs]);

  useEffect(() => {
    let templateCompanyDetailsController = null;
    if (typeof loggedInAs === "function" && !loggedInAs("AD")) {
      const successHandler = (event) => {
        event &&
          event.result &&
          dispatch({ templateCompanyDetails: event.result });
      };
      templateCompanyDetailsController =
        getTemplateCompanyDetails(successHandler);
    }
    return () => {
      if (templateCompanyDetailsController?.abort) {
        templateCompanyDetailsController.abort();
      }
    };
  }, [getTemplateCompanyDetails, loggedInAs]);

  useEffect(() => {
    return () => {
      resetLoadingStates();
    };
  }, [resetLoadingStates]);

  const updateSettings = (type) => {
    return updateSettingsHelper(
      type,
      propertySettings,
      selectedID,
      setShowLoader,
      dispatch,
      updatePropertySettings
    );
  };

  const requestOwnerData = () => {
    toggleMainLoader(true);
    const successHandler = (data) => {
      successMessage(7002);
      setConfirmRequest(false);
      toggleMainLoader(false);
      setUpdatePropertyInfo(true);
    };
    const errorHandler = (event) => {
      errorMessage(event);
      setConfirmRequest(false);
      toggleMainLoader(false);
    };

    setOwnerInfoRequests([selectedID], successHandler, errorHandler);
  };

  // Update address and showGoogleStreetView
  useEffect(() => {
    if (showStreetAddress) {
      dispatch({ type: "SET_STREET_ADDRESS", payload: showStreetAddress });
    }
  }, [showStreetAddress]);

  console.log("Property Details", propertyOwnerInfo);

  return (
    <section className="moovsoon-page-container pt-0">
      <Preloader show={showLoader} />
      <div className="moovsoon-page-body">
        <div className="market-details">
          <Button
            type="button"
            value="< Back"
            className="btn btn-default btn-sm mr-2 mb-3"
            onClick={() => {
              clearProperty();
              setPersistFilter(true, () => {
                dispatch({ dataLoaded: false });
                realtorId
                  ? history.push(`/listings?realtorId=${realtorId}`)
                  : history.push(`/listings`, { fromPropertyView: true });
              });
            }}
          />

          <>
            <div className="card card-primary mb-3 h-100">
              <div className="d-flex flex-row align-items-center">
                <div className="w-75 p-3">
                  <TimeLine
                    propertyDetails={propertyDetails}
                    dataLoaded={dataLoaded}
                    isPropertyDetailsLoading={loadingStates.propertyDetails}
                  />
                </div>
                {typeof loggedInAs === "function" &&
                !loggedInAs("AD") &&
                trackConversionData ? (
                  <div className="w-25 d-flex align-items-end flex-column mr-2">
                    <Tooltip
                      message="Update your lead conversion status"
                      component={
                        <button
                          disabled={loadingStates.propertyDetails}
                          type="button"
                          className="btn btn-default btn-sm m-3"
                          onClick={() => showtrackConversion(true)}>
                          Track Conversion
                        </button>
                      }
                    />
                  </div>
                ) : null}
              </div>
              <div className="row">
                <SliderBlock
                  propertyDetails={propertyDetails}
                  isAdmin={loggedInAs("AD")}
                />

                <div className="col-5 pl-0">
                  <div className="d-flex flex-column">
                    {typeof loggedInAs === "function" && !loggedInAs("AD") ? (
                      <div className="d-flex flex-row bb-1 pt-0 mb-4 h-48">
                        <SettingsBlock
                          {...{
                            propertySettings,
                            updateSettings,
                            isPropertyDetailsLoading:
                              loadingStates.propertyDetails,
                          }}
                        />

                        <AutomaticProspectingBlock
                          {...{
                            initialValues: getPropertyNotificationData(
                              propertyNotificationSetting
                            ),
                            propertyNotificationSetting,
                            loggedInAs,
                            ownerTemplateProspectingEvents,
                            relatorTemplateProspectingEvents,
                            templateList,
                            formValues: automaticProspectingformValues,
                            userDetails,
                            selectedID,
                            getPropertyNotificationSetting,
                            savePropertyNotification,
                            setShowLoader,
                            successMessage,
                            setPropertyNotificationSetting,
                            isPropertyDetailsLoading:
                              loadingStates.propertyDetails,
                          }}
                        />
                      </div>
                    ) : null}

                    <PropertyInfoBlock
                      {...{
                        propertyDetails,
                        updateSettings,
                        loggedInAs,
                        propertySettings,
                        propertyOwnerInfo,
                        isPropertyDetailsLoading: loadingStates.propertyDetails,
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="card card-primary p-4 h-100 bt-2">
              <div className="row d-flex flex-row">
                <div className="w-50 relator-block">
                  {propertyDetails ? (
                    <RealtorBlock
                      {...{
                        propertyDetails,
                        dispatch,
                        setShowLoader,
                        loggedInAs,
                        setCommunicationFeedType,
                        history,
                        match,
                        isPropertyDetailsLoading: loadingStates.propertyDetails,
                      }}
                    />
                  ) : (
                    <div className="alert alert-info p-3 text-center">
                      No property details available
                    </div>
                  )}
                </div>

                <div className="w-50 pl-2">
                  {propertyOwnerInfo ? (
                    <OwnerBlock
                      {...{
                        selectedID,
                        propertyOwnerInfo,
                        fetchPropertyOwnerInfo,
                        dispatch,
                        setShowLoader,
                        loggedInAs,
                        setCommunicationFeedType,
                        setOwnerInfoRequests,
                        requestOwnerData,
                        confirmRequest,
                        setConfirmRequest,
                        isPropertyOwnerInfoLoading,
                      }}
                    />
                  ) : (
                    <div className="alert alert-info p-3 text-center">
                      Loading Data
                    </div>
                  )}
                </div>
              </div>
            </div>

            {communicationFeedType === 1 || communicationFeedType === 2 ? (
              <div className="mt-3">
                <CommunicationFeed
                  getCommunicationFeed={getRealtorCommunicationFeed}
                  realtor={{
                    realtorId: propertyDetails.realtorId,
                  }}
                  propertyOwnerId={
                    propertyOwnerInfo && propertyOwnerInfo.ownerInfo
                      ? propertyOwnerInfo.ownerInfo.propertyOwnerId
                      : null
                  }
                  isPropertyPage={1}
                  communicationFeedType={communicationFeedType}
                  propertyId={selectedID}
                />
              </div>
            ) : null}
          </>
        </div>
      </div>
      {propertyDetails && dataLoaded ? (
        <>
          <SidePanel
            show={showMailPanel}
            onHide={() => dispatch({ showMailPanel: false })}
            component={EmailPanel}
            enforceFocus={false}
            className="create-template-panel create-template-panel-sm"
            componentProps={{
              setShowLoader,
              dispatch,
              selectedID,
              data: templateCompanyDetails,
              formValues: emailTemplateformValues,
              change,
              sendManualProspecting,
              propertyDetails,
              propertyOwnerInfo,
              manualProspectingType,
              toggleMainLoader,
            }}
          />

          <ResizableGoogleStreetView
            address={showStreetAddress}
            show={showGoogleStreetView}
            onClose={() => dispatch({ showGoogleStreetView: false })}
            //onClose={() => dispatch({ type: 'SET_GOOGLE_STREET_VIEW', payload: false, showGoogleStreetView: false })}
          />

          <SidePanel
            show={showPostCardPanel}
            onHide={() => dispatch({ showPostCardPanel: false })}
            component={MailerPanel}
            enforceFocus={false}
            className="create-template-panel create-template-panel-mm"
            componentProps={{
              setShowLoader,
              dispatch,
              selectedID,
              data: templateCompanyDetails,
              formValues: mailerTemplateformValues,
              change,
              sendManualProspecting,
              propertyDetails,
              propertyOwnerInfo,
              manualProspectingType,
              toggleMainLoader,
              mailerSizeOptions,
              templateSizeId,
            }}
          />

          <SidePanel
            show={showSMSPanel}
            onHide={() => dispatch({ showSMSPanel: false })}
            component={SMSPanel}
            className="create-template-panel create-template-panel-sm"
            componentProps={{
              setShowLoader,
              dispatch,
              selectedID,
              sendManualProspecting,
              propertyDetails,
              propertyOwnerInfo,
              manualProspectingType,
              toggleMainLoader,
            }}
          />

          <SidePanel
            show={trackConversionDisplay}
            onHide={() => showtrackConversion(false)}
            component={TrackConversionPanel}
            componentProps={{
              setShowLoader,
              successMessage,
              initialValues: trackConversionData,
              showtrackConversion,
              setTrackConversionData,
              formValues: trackConversionformValues,
              loggedInAs,
              selectedID,
              propertySourceList,
              moveTypeList,
              updateTrackConversion,
              dataReload,
              setDataReload,
            }}
          />
        </>
      ) : null}
    </section>
  );
};

const mapStateToProps = (state) => {
  return {
    propertyDetails: get(state, "Property.propertyDetails", null),
    userDetails: get(state, "Auth.userDetails", {}),
    automaticProspectingformValues: getFormValues(
      "PropertyautomaticProspectingForm"
    )(state),
    emailTemplateformValues: getFormValues("emailTemplateForm")(state),
    mailerTemplateformValues: getFormValues("mailerTemplateForm")(state),
    trackConversionformValues: getFormValues("trackConversionForm")(state),
    loadingStates: get(state, "Property.loadingStates", {}),
  };
};

const mapDispatchToProps = {
  getProperty,
  clearProperty,
  updatePropertySettings,
  getmarketTemplateProspectingEvents,
  getAllmarketTemplates,
  getPropertyNotificationSetting,
  savePropertyNotification,
  getPropertySourceList,
  getMoveTypeList,
  updateTrackConversion,
  getPropertyOwnerInfo,
  fetchPropertyOwnerInfo,
  getTemplateCompanyDetails,
  getTrackConversion,
  getRealtorCommunicationFeed,
  sendManualProspecting,
  toggleMainLoader,
  setPersistFilter,
  getMailerSizeOptions,
  setOwnerInfoRequests,
  resetLoadingStates,
};

export default connect(mapStateToProps, mapDispatchToProps)(PropertyDetails);
