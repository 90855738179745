import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import {
    getCampaignsByType,
    getBookerServiceProviders,
    getSalesPersons,
    createAutomatedCampaign,
    getMarketCompanies,
    getAutomatedCampaignForEdit,
    updateAutomatedCampaign
} from "../../store/actions";
import { errorMessage } from "../../utils/errorMessage";
import { MOVE_TYPE_OPTIONS } from "../../constant/movscoutCampaignManagement";

const CreateAutomatedCampaignModal = ({
    show,
    onClose,
    getCampaignsByType,
    getBookerServiceProviders,
    getSalesPersons,
    createAutomatedCampaign,
    getMarketCompanies,
    automatedCampaignId,
    isEditMode,
    getAutomatedCampaignForEdit,
    updateAutomatedCampaign
}) => {
    // Form state
    const [formData, setFormData] = useState({
        name: "",
        moveType: "",
        booker: "",
        salesPersonId: "",
        salesPerson: "",
        preSaleCampaign: "",
        reEngageCampaign: "",
        closerCampaign: ""
    });

    // Validation state
    const [errors, setErrors] = useState({});

    // Loading states
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isLoadingCampaignData, setIsLoadingCampaignData] = useState(false);
    const [campaignOptions, setCampaignOptions] = useState({
        preSale: [],
        reEngage: [],
        closer: []
    });
    const [isLoadingCampaigns, setIsLoadingCampaigns] = useState({
        preSale: true,
        reEngage: true,
        closer: true
    });

    // Booker and sales person states
    const [bookerOptions, setBookerOptions] = useState([]);
    const [salesPersonOptions, setSalesPersonOptions] = useState([]);
    const [salesPersonUserOptions, setSalesPersonUserOptions] = useState([]);
    const [isLoadingBookers, setIsLoadingBookers] = useState(true);
    const [isLoadingSalesPersons, setIsLoadingSalesPersons] = useState(true);
    const [isLoadingSalesPersonUsers, setIsLoadingSalesPersonUsers] = useState(true);

    // Custom select states
    const [newBookerInput, setNewBookerInput] = useState("");
    const [newSalesPersonInput, setNewSalesPersonInput] = useState("");
    const [showNewBookerInput, setShowNewBookerInput] = useState(false);
    const [showNewSalesPersonInput, setShowNewSalesPersonInput] = useState(false);

    // Fetch campaign data if in edit mode
    useEffect(() => {
        if (show && isEditMode && automatedCampaignId) {
            setIsLoadingCampaignData(true);
            getAutomatedCampaignForEdit(
                automatedCampaignId,
                (response) => {
                    if (response && response.result) {
                        const campaignData = response.result;
                        setFormData({
                            name: campaignData.name || "",
                            moveType: campaignData.moveType?.toString() || "",
                            booker: campaignData.bookerId || "",
                            salesPersonId: campaignData.salesPersonQLABId || "",
                            salesPerson: campaignData.salesPersonId || "",
                            preSaleCampaign: campaignData.preSaleCampaign || "",
                            reEngageCampaign: campaignData.reEngageCampaign || "",
                            closerCampaign: campaignData.closerCampaign || ""
                        });
                    }
                    setIsLoadingCampaignData(false);
                },
                (error) => {
                    console.error('Error fetching automated campaign data:', error);
                    errorMessage(error?.message || "Failed to load campaign data");
                    setIsLoadingCampaignData(false);
                }
            );
        } else if (show && !isEditMode) {
            // Reset form data when opening for create mode
            setFormData({
                name: "",
                moveType: "",
                booker: "",
                salesPersonId: "",
                salesPerson: "",
                preSaleCampaign: "",
                reEngageCampaign: "",
                closerCampaign: ""
            });
        }
    }, [show, isEditMode, automatedCampaignId, getAutomatedCampaignForEdit]);

    // Fetch campaign options, bookers, sales persons, and company users when modal opens
    useEffect(() => {
        if (show) {
            // Fetch Pre-sale campaigns (type 1)
            setIsLoadingCampaigns(prev => ({ ...prev, preSale: true }));
            getCampaignsByType(
                1,
                (response) => {
                    setCampaignOptions(prev => ({
                        ...prev,
                        preSale: response.result || []
                    }));
                    setIsLoadingCampaigns(prev => ({ ...prev, preSale: false }));
                },
                (error) => {
                    console.error('Error fetching Pre-sale campaigns:', error);
                    setIsLoadingCampaigns(prev => ({ ...prev, preSale: false }));
                }
            );

            // Fetch Re-engage campaigns (type 2)
            setIsLoadingCampaigns(prev => ({ ...prev, reEngage: true }));
            getCampaignsByType(
                2,
                (response) => {
                    setCampaignOptions(prev => ({
                        ...prev,
                        reEngage: response.result || []
                    }));
                    setIsLoadingCampaigns(prev => ({ ...prev, reEngage: false }));
                },
                (error) => {
                    console.error('Error fetching Re-engage campaigns:', error);
                    setIsLoadingCampaigns(prev => ({ ...prev, reEngage: false }));
                }
            );

            // Fetch Closer campaigns (type 3)
            setIsLoadingCampaigns(prev => ({ ...prev, closer: true }));
            getCampaignsByType(
                3,
                (response) => {
                    setCampaignOptions(prev => ({
                        ...prev,
                        closer: response.result || []
                    }));
                    setIsLoadingCampaigns(prev => ({ ...prev, closer: false }));
                },
                (error) => {
                    console.error('Error fetching Closer campaigns:', error);
                    setIsLoadingCampaigns(prev => ({ ...prev, closer: false }));
                }
            );

            // Fetch Booker Service Providers
            setIsLoadingBookers(true);
            getBookerServiceProviders(
                (response) => {
                    setBookerOptions(response.result || []);
                    setIsLoadingBookers(false);
                },
                (error) => {
                    console.error('Error fetching booker service providers:', error);
                    setIsLoadingBookers(false);
                }
            );

            // Fetch Sales Persons
            setIsLoadingSalesPersons(true);
            getSalesPersons(
                (response) => {
                    setSalesPersonOptions(response.result || []);
                    setIsLoadingSalesPersons(false);
                },
                (error) => {
                    console.error('Error fetching sales persons:', error);
                    setIsLoadingSalesPersons(false);
                }
            );

            // Fetch Sales Person Users
            setIsLoadingSalesPersonUsers(true);
            getMarketCompanies(
                { marketType: 1 },
                (response) => {
                    setSalesPersonUserOptions(response.result || []);
                    setIsLoadingSalesPersonUsers(false);
                },
                (error) => {
                    console.error('Error fetching sales person users:', error);
                    setIsLoadingSalesPersonUsers(false);
                }
            );
        }
    }, [show, getCampaignsByType, getBookerServiceProviders, getSalesPersons, getMarketCompanies]);

    // Handle input changes - Modified to properly handle select options
    const handleChange = (e) => {
        const { name, value } = e.target;

        // For all fields, just update the value directly
        setFormData(prev => ({
            ...prev,
            [name]: value
        }));

        // Clear error for this field when user types
        if (errors[name]) {
            setErrors(prev => ({
                ...prev,
                [name]: ""
            }));
        }
    };

    // Handle adding new booker
    const handleAddNewBooker = () => {
        if (newBookerInput.trim()) {
            const newBookerId = newBookerInput.trim();

            // Add to form data
            setFormData(prev => ({
                ...prev,
                booker: newBookerId
            }));

            // Add to local options if not already there
            if (!bookerOptions.some(b => b.id === newBookerId)) {
                setBookerOptions(prev => [
                    ...prev,
                    { id: newBookerId, bookerId: newBookerId, booker: newBookerId }
                ]);
            }

            setShowNewBookerInput(false);
            setNewBookerInput("");
        }
    };

    // Handle adding new sales person
    const handleAddNewSalesPerson = () => {
        if (newSalesPersonInput.trim()) {
            const newSalesPersonId = newSalesPersonInput.trim();

            // Add to form data
            setFormData(prev => ({
                ...prev,
                salesPersonId: newSalesPersonId
            }));

            // Add to local options if not already there
            if (!salesPersonOptions.some(p => p.id === newSalesPersonId)) {
                setSalesPersonOptions(prev => [
                    ...prev,
                    { id: newSalesPersonId, salesPersonId: newSalesPersonId, salesPersonQLABId: newSalesPersonId }
                ]);
            }

            setShowNewSalesPersonInput(false);
            setNewSalesPersonInput("");
        }
    };

    // Handle key press for custom inputs
    const handleKeyPress = (e, handler) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            handler();
        }
    };

    // Validate form
    const validateForm = () => {
        const newErrors = {};

        if (!formData.name.trim()) {
            newErrors.name = "Campaign name is required";
        }

        if (!formData.moveType) {
            newErrors.moveType = "Move type is required";
        }

        if (!formData.booker) {
            newErrors.booker = "Booker is required";
        }

        if (!formData.salesPersonId) {
            newErrors.salesPersonId = "Sales Person ID is required";
        }

        if (!formData.salesPerson) {
            newErrors.salesPerson = "Sales Person is required";
        }

        // At least one campaign type must be selected
        if (!formData.preSaleCampaign && !formData.reEngageCampaign && !formData.closerCampaign) {
            newErrors.campaigns = "At least one campaign type must be selected";
        }

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    // Handle form submission
    const handleSubmit = () => {
        if (!validateForm()) {
            // Show error notification when validation fails
            errorMessage(null, "Invalid form submission");
            return;
        }

        setIsSubmitting(true);

        // Format data for API
        const automatedCampaign = {
            name: formData.name,
            moveType: parseInt(formData.moveType), // Ensure moveType is an integer
            bookerId: formData.booker,
            salesPersonId: formData.salesPersonId,
            salesPerson: formData.salesPerson,
            preSaleCampaign: formData.preSaleCampaign || null,
            reEngageCampaign: formData.reEngageCampaign || null,
            closerCampaign: formData.closerCampaign || null
        };

        if (isEditMode && automatedCampaignId) {
            // Update existing automated campaign
            updateAutomatedCampaign(
                automatedCampaignId,
                automatedCampaign,
                (response) => {
                    setIsSubmitting(false);
                    onClose(true); // Close with success flag
                },
                (error) => {
                    console.error('Error updating automated campaign:', error);
                    setIsSubmitting(false);
                    setErrors(prev => ({
                        ...prev,
                        submit: "Failed to update automated campaign. Please try again."
                    }));
                    // Show error notification for API failure
                    errorMessage(error?.message || "Failed to update automated campaign. Please try again.");
                }
            );
        } else {
            // Create new automated campaign
            createAutomatedCampaign(
                automatedCampaign,
                (response) => {
                    setIsSubmitting(false);
                    onClose(true); // Close with success flag
                },
                (error) => {
                    console.error('Error creating automated campaign:', error);
                    setIsSubmitting(false);
                    setErrors(prev => ({
                        ...prev,
                        submit: "Failed to create automated campaign. Please try again."
                    }));
                    // Show error notification for API failure
                    errorMessage(error?.message || "Failed to create automated campaign. Please try again.");
                }
            );
        }
    };

    // Get display name for selected booker
    const getSelectedBookerName = () => {
        if (!formData.booker) return "";

        const selectedBooker = bookerOptions.find(b => b.id === formData.booker);
        return selectedBooker ? selectedBooker.name : formData.booker;
    };

    // Get display name for selected sales person
    const getSelectedSalesPersonName = () => {
        if (!formData.salesPersonId) return "";

        const selectedPerson = salesPersonOptions.find(p => p.id === formData.salesPersonId);
        return selectedPerson ? selectedPerson.name : formData.salesPersonId;
    };

    // Get display name for selected sales person user
    const getSelectedSalesPersonUserName = () => {
        if (!formData.salesPerson) return "";

        const selectedUser = salesPersonUserOptions.find(u => u.userId === formData.salesPerson);
        return selectedUser ? selectedUser.fullName : "";
    };

    return (
        <div className="form-container">
            {isLoadingCampaignData ? (
                <div className="text-center py-5">
                    <div className="spinner-border text-primary" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                    <p className="mt-3">Loading campaign data...</p>
                </div>
            ) : (
                <>
                    <div className="form-group">
                        {errors.name && <div className="text-danger mt-2">{errors.name}</div>}
                        <input
                            type="text"
                            className={`form-control ${errors.name ? 'is-invalid' : ''}`}
                            placeholder="Campaign Name"
                            name="name"
                            value={formData.name}
                            onChange={handleChange}
                        />
                        <label className="form-label">Name</label>
                    </div>

                    <div className="form-group">
                        {errors.moveType && <div className="text-danger mt-2">{errors.moveType}</div>}
                        <select
                            className={`form-control ${errors.moveType ? 'is-invalid' : ''}`}
                            name="moveType"
                            value={formData.moveType}
                            onChange={handleChange}
                        >
                            <option value="">Select move type</option>
                            {MOVE_TYPE_OPTIONS.map(option => (
                                <option key={option.value} value={option.value}>
                                    {option.label}
                                </option>
                            ))}
                        </select>
                        <label className="form-label">Move Type</label>
                    </div>

                    <div className="form-group">
                        {errors.booker && <div className="text-danger mt-2">{errors.booker}</div>}
                        {!showNewBookerInput ? (
                            <div className="d-flex flex-column">
                                <div className="custom-select-container">
                                    <label className="form-label">Booker</label>
                                    <select
                                        className={`form-control ${errors.booker ? 'is-invalid' : ''}`}
                                        name="booker"
                                        value={formData.booker}
                                        onChange={handleChange}
                                        disabled={isLoadingBookers}
                                    >
                                        <option value="">
                                            {isLoadingBookers ? "Loading bookers..." : "Select booker"}
                                        </option>
                                        {bookerOptions.map(booker => (
                                            <option key={booker.id} value={booker.bookerId}>
                                                {booker.booker}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                                <button
                                    type="button"
                                    className="btn btn-link text-primary p-0 mt-1 align-self-start"
                                    onClick={() => {
                                        setNewBookerInput("");
                                        setShowNewBookerInput(true);
                                    }}
                                    style={{ fontSize: '14px' }}
                                    disabled={isLoadingBookers}
                                >
                                    <span className="text-primary">+</span> Add new booker
                                </button>
                            </div>
                        ) : (
                            <div className="d-flex flex-column">
                                <div className="input-group">
                                    <label className="form-label">Booker</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter new booker ID"
                                        value={newBookerInput}
                                        onChange={(e) => setNewBookerInput(e.target.value)}
                                        onKeyPress={(e) => handleKeyPress(e, handleAddNewBooker)}
                                        autoFocus
                                    />
                                </div>
                                <div className="d-flex mt-2">
                                    <button
                                        type="button"
                                        className="btn btn-sm btn-success mr-2"
                                        onClick={handleAddNewBooker}
                                        disabled={!newBookerInput.trim()}
                                    >
                                        Add
                                    </button>
                                    <button
                                        type="button"
                                        className="btn btn-sm btn-outline-secondary"
                                        onClick={() => setShowNewBookerInput(false)}
                                    >
                                        Cancel
                                    </button>
                                </div>
                            </div>
                        )}
                    </div>

                    <div className="form-group">
                        {errors.salesPersonId && <div className="text-danger mt-2">{errors.salesPersonId}</div>}
                        {!showNewSalesPersonInput ? (
                            <div className="d-flex flex-column">
                                <div className="custom-select-container">
                                    <label className="form-label">Sales Person ID</label>
                                    <select
                                        className={`form-control ${errors.salesPersonId ? 'is-invalid' : ''}`}
                                        name="salesPersonId"
                                        value={formData.salesPersonId}
                                        onChange={handleChange}
                                        disabled={isLoadingSalesPersons}
                                    >
                                        <option value="">
                                            {isLoadingSalesPersons ? "Loading sales persons..." : "Select sales person"}
                                        </option>
                                        {salesPersonOptions.map(person => (
                                            <option key={person.id} value={person.salesPersonId}>
                                                {person.salesPersonQLABId}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                                <button
                                    type="button"
                                    className="btn btn-link text-primary p-0 mt-1 align-self-start"
                                    onClick={() => {
                                        setNewSalesPersonInput("");
                                        setShowNewSalesPersonInput(true);
                                    }}
                                    style={{ fontSize: '14px' }}
                                    disabled={isLoadingSalesPersons}
                                >
                                    <span className="text-primary">+</span> Add new sales person
                                </button>
                            </div>
                        ) : (
                            <div className="d-flex flex-column">
                                <div className="input-group">
                                    <label className="form-label">Sales Person ID</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter new sales person ID"
                                        value={newSalesPersonInput}
                                        onChange={(e) => setNewSalesPersonInput(e.target.value)}
                                        onKeyPress={(e) => handleKeyPress(e, handleAddNewSalesPerson)}
                                        autoFocus
                                    />
                                </div>
                                <div className="d-flex mt-2">
                                    <button
                                        type="button"
                                        className="btn btn-sm btn-success mr-2"
                                        onClick={handleAddNewSalesPerson}
                                        disabled={!newSalesPersonInput.trim()}
                                    >
                                        Add
                                    </button>
                                    <button
                                        type="button"
                                        className="btn btn-sm btn-outline-secondary"
                                        onClick={() => setShowNewSalesPersonInput(false)}
                                    >
                                        Cancel
                                    </button>
                                </div>
                            </div>
                        )}
                    </div>

                    {/* Sales Person User Select Field */}
                    <div className="form-group">
                        {errors.salesPerson && <div className="text-danger mt-2">{errors.salesPerson}</div>}
                        <div className="custom-select-container">
                            <label className="form-label">Sales Person</label>
                            <select
                                className={`form-control ${errors.salesPerson ? 'is-invalid' : ''}`}
                                name="salesPerson"
                                value={formData.salesPerson}
                                onChange={handleChange}
                                disabled={isLoadingSalesPersonUsers}
                            >
                                <option value="">
                                    {isLoadingSalesPersonUsers ? "Loading sales persons..." : "Select sales person"}
                                </option>
                                {salesPersonUserOptions.map(user => (
                                    <option key={user.userId} value={user.userId}>
                                        {user.fullName}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div>

                    <div className="campaign-sections">
                        {errors.campaigns && <div className="text-danger">{errors.campaigns}</div>}
                        <div className="campaign-section">
                            <h4>Pre-Sale Campaign</h4>
                            <p className="section-description">
                                Automatically sends to new leads with scheduled surveys
                            </p>
                            <select
                                className="form-control"
                                name="preSaleCampaign"
                                value={formData.preSaleCampaign}
                                onChange={handleChange}
                                disabled={isLoadingCampaigns.preSale}
                            >
                                <option value="">
                                    {isLoadingCampaigns.preSale
                                        ? "Loading campaigns..."
                                        : "Select Pre-sale campaign"}
                                </option>
                                {campaignOptions.preSale.map((campaign) => (
                                    <option key={campaign.id} value={campaign.campaignId}>
                                        {campaign.templateName}
                                    </option>
                                ))}
                            </select>
                        </div>

                        <div className="campaign-section">
                            <h4>Re-engage Campaign</h4>
                            <p className="section-description">
                                Targets leads marked as "attempted contact" or "ghosted"
                            </p>
                            <select
                                className="form-control"
                                name="reEngageCampaign"
                                value={formData.reEngageCampaign}
                                onChange={handleChange}
                                disabled={isLoadingCampaigns.reEngage}
                            >
                                <option value="">
                                    {isLoadingCampaigns.reEngage
                                        ? "Loading campaigns..."
                                        : "Select Re-engage campaign"}
                                </option>
                                {campaignOptions.reEngage.map((campaign) => (
                                    <option key={campaign.id} value={campaign.campaignId}>
                                        {campaign.templateName}
                                    </option>
                                ))}
                            </select>
                        </div>

                        <div className="campaign-section">
                            <h4>Closer Campaign</h4>
                            <p className="section-description">
                                Engages with leads in "pending" status to drive conversion
                            </p>
                            <select
                                className="form-control"
                                name="closerCampaign"
                                value={formData.closerCampaign}
                                onChange={handleChange}
                                disabled={isLoadingCampaigns.closer}
                            >
                                <option value="">
                                    {isLoadingCampaigns.closer
                                        ? "Loading campaigns..."
                                        : "Select Closer campaign"}
                                </option>
                                {campaignOptions.closer.map((campaign) => (
                                    <option key={campaign.id} value={campaign.campaignId}>
                                        {campaign.templateName}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div>

                    <div className="activation-summary">
                        <h4>Automation Rules</h4>
                        <div className="summary-items">
                            <div className="summary-item">
                                <i className="icon icon-inbox"></i>
                                <div>
                                    <h5>Pre-sale</h5>
                                    <p>Triggers: New lead + Survey scheduled</p>
                                </div>
                            </div>
                            <div className="summary-item">
                                <i className="icon icon-sms"></i>
                                <div>
                                    <h5>Re-engage</h5>
                                    <p>
                                        Triggers: Lead status changes to "Attempted contact" or
                                        "Ghosted"
                                    </p>
                                </div>
                            </div>
                            <div className="summary-item">
                                <i className="icon icon-wallet"></i>
                                <div>
                                    <h5>Closer</h5>
                                    <p>Triggers: Lead status changes to "Pending"</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    {errors.submit && <div className="text-danger mt-3">{errors.submit}</div>}

                    <div className="d-flex justify-content-end mt-4">
                        <button
                            className="btn btn-default mr-2"
                            onClick={() => onClose(false)}
                            disabled={isSubmitting}
                        >
                            Cancel
                        </button>
                        <button
                            className="btn btn-primary"
                            onClick={handleSubmit}
                            disabled={isSubmitting}
                        >
                            {isSubmitting ?
                                (isEditMode ? "Updating..." : "Creating...") :
                                (isEditMode ? "Update" : "Activate")}
                        </button>
                    </div>
                </>
            )}
        </div>
    );
};

const mapDispatchToProps = {
    getCampaignsByType,
    getBookerServiceProviders,
    getSalesPersons,
    createAutomatedCampaign,
    getMarketCompanies,
    getAutomatedCampaignForEdit,
    updateAutomatedCampaign
};

export default connect(null, mapDispatchToProps)(CreateAutomatedCampaignModal);