import moment from "moment";
import React from "react";
import { normalizePhone } from "../../../constant/formValidators";
import Tooltip from "../../../utils/tooltip";

const formatAddress = (address, city, state, zip) => {
  if (address && !/^tbd$/i.test(address)) return address;
  const formattedAddress = [zip, city, state].filter(Boolean).join(", ");
  return formattedAddress || "N/A";
};

export function capitalizeWords(str) {
  if (!str || typeof str !== "string") {
    return "";
  }

  return str
    .split(" ")
    .map((word) => {
      if (word.length === 0) {
        return word;
      }
      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    })
    .join(" ");
}

export function generateColumns({
  onSelectRow,
  onToggleExpand,
  getLeadPropertyMatch,
  resetLeadPropertyMatchLoadingState,
}) {
  return [
    {
      name: "Owner Name",
      accessor: "ownerName",
      className: "col-owner-name",
      sortable: false,
      render: (row) => {
        const payload = JSON.parse(row.payload || "{}");
        const ownerName = `${payload.FirstName || ""} ${payload.LastName || ""
          }`.trim();
        const isInCampaign = Math.random() < 0.5;

        return (
          <div
            className="owner-name-wrapper text-primary pointer ellipsis"
            onClick={() => onSelectRow(row)}>
            <span className="campaign-icon-wrapper">
              {isInCampaign ? (
                <Tooltip
                  message="In Campaign"
                  component={
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      className="lucide lucide-mail-check">
                      <path d="M22 13V6a2 2 0 0 0-2-2H4a2 2 0 0 0-2 2v12c0 1.1.9 2 2 2h8" />
                      <path d="m22 7-8.97 5.7a1.94 1.94 0 0 1-2.06 0L2 7" />
                      <path d="m16 19 2 2 4-4" />
                    </svg>
                  }
                />
              ) : (
                <Tooltip
                  message="Not in Campaign"
                  component={
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      className="lucide lucide-mail icon-not-in-campaign">
                      <rect width="20" height="16" x="2" y="4" rx="2" />
                      <path d="m22 7-8.97 5.7a1.94 1.94 0 0 1-2.06 0L2 7" />
                    </svg>
                  }
                />
              )}
            </span>
            <Tooltip
              message={ownerName || "N/A"}
              component={
                <span className="ellipsis">
                  {ownerName || "N/A"}
                </span>
              }
            />
          </div>
        );
      },
    },
    {
      name: "Phone",
      accessor: "phone",
      className: "col-phone",
      sortable: false,
      render: (row) => {
        const payload = JSON.parse(row.payload || "{}");
        if (!payload.HomePhoneNumber && !payload.CellPhoneNumber) return "N/A"
        const normalizedNumber = normalizePhone(
          payload.HomePhoneNumber ||
          payload.CellPhoneNumber
        )
        return <Tooltip
          message={normalizedNumber}
          component={
            <span className="ellipsis">
              {normalizedNumber}
            </span>
          }
        />

      },
    },
    {
      name: "Email",
      accessor: "email",
      className: "col-email",
      sortable: false,
      render: (row) => {
        const payload = JSON.parse(row.payload || "{}");
        if (!payload.EmailAddress) return "N/A"
        return <Tooltip
          message={payload.EmailAddress}
          component={
            <span className="ellipsis">
              {payload.EmailAddress}
            </span>
          }
        />
      },
    },
    {
      name: "Booker",
      accessor: "BookerServiceProvider",
      className: "col-booker",
      sortable: false,
      render: (row) => {
        const payload = JSON.parse(row.payload || "{}");
        return <div>{payload.BookerServiceProvider || "N/A"}</div>;
      },
    },
    {
      name: "Origin City",
      accessor: "originCity",
      className: "col-origin-city",
      sortable: false,
      render: (row) => {
        const payload = JSON.parse(row.payload || "{}");
        return payload.OriginCity ? capitalizeWords(payload.OriginCity) : "N/A";
      },
    },
    {
      name: "Origin State",
      accessor: "originState",
      className: "col-origin-state",
      sortable: false,
      render: (row) => {
        const payload = JSON.parse(row.payload || "{}");
        return payload.OriginState || "N/A";
      },
    },
    {
      name: "Dest. City",
      accessor: "destinationCity",
      className: "col-destination-city",
      sortable: false,
      render: (row) => {
        const payload = JSON.parse(row.payload || "{}");
        return payload.DestinationCity
          ? capitalizeWords(payload.DestinationCity)
          : "N/A";
      },
    },
    {
      name: "Dest. State",
      accessor: "destinationState",
      className: "col-destination-state",
      sortable: false,
      render: (row) => {
        const payload = JSON.parse(row.payload || "{}");
        return payload.DestinationState || "N/A";
      },
    },
    {
      name: "Move Type",
      accessor: "moveType",
      className: "col-move-type",
      sortable: false,
    },
    {
      name: "Req. Move Date",
      accessor: "requestedMoveDate",
      className: "col-requested-move-date",
      sortable: false,
      render: (row) => {
        const payload = JSON.parse(row.payload || "{}");
        return payload.RequestedMoveDate
          ? moment(payload.RequestedMoveDate).format("MM/DD/YYYY")
          : "N/A";
      },
    },
    {
      name: "Lead Status",
      accessor: "disposition",
      className: "col-lead-status",
      sortable: false,
      render: (row) => {
        const validStatuses = [
          "new",
          "attempted-contact",
          "survey-scheduled",
          "survey-completed",
          "ready-to-book",
          "pending",
          "booked",
          "lost",
          "inactive",
        ];

        const statusClass = row.disposition
          ? validStatuses.includes(
            row.disposition.toLowerCase().replace(/\s+/g, "-")
          )
            ? `lead-badge-${row.disposition.toLowerCase().replace(/\s+/g, "-")}`
            : "lead-badge-default"
          : "lead-badge-default";

        return (
          <div className="badge-wrapper">
            <div className={`badge-content ${statusClass}`}>
              {row.disposition}
            </div>
          </div>
        );
      },
    },
    {
      name: "Created Date",
      accessor: "createdAt",
      className: "col-createdAt-date",
      sortable: false,
      render: (row) => {
        return row.createdAt
          ? moment(row.createdAt).format("MM/DD/YYYY")
          : "N/A";
      },
    },
    {
      name: "Last Contact Date",
      accessor: "lastContactDate",
      className: "col-last-contact-date",
      sortable: false,
      render: (row) => {
        return moment(new Date()).format("MM/DD/YYYY");
      },
    },
    {
      name: "",
      accessor: "expand",
      className: "col-expand",
      sortable: false,
      render: (row, column, rowIndex) => (
        <Tooltip
          message="Expand Details"
          component={
            <button
              className="btn btn-expand"
              onClick={(e) => {
                e.stopPropagation();
                onToggleExpand(rowIndex);
              }}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="18"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="lucide lucide-chevrons-up-down">
                <path d="m7 15 5 5 5-5" />
                <path d="m7 9 5-5 5 5" />
              </svg>
            </button>
          }
        />
      ),
    },
  ];
}

export const DATE_FILTER_KEYS = {
  createdFrom: "datecreatedfrom",
  createdTo: "datecreatedto",
  modifiedFrom: "datemodifiedfrom",
  modifiedTo: "datemodifiedto",
};

export const DATE_FILTER_LABELS = {
  [DATE_FILTER_KEYS.createdFrom]: "Date Created From",
  [DATE_FILTER_KEYS.createdTo]: "Date Created To",
  [DATE_FILTER_KEYS.modifiedFrom]: "Date Modified From",
  [DATE_FILTER_KEYS.modifiedTo]: "Date Modified To",
};

export const filtersArray = ({
  selectedDate,
  setSelectedDate,
  selectedModifiedDate,
  setSelectedModifiedDate,
  filterSelectedValues,
}) => {
  const leadStatusOptions = [
    { value: "", label: "Select" },
    { value: "New", label: "New" },
    { value: "Attempted Contact", label: "Attempted Contact" },
    { value: "Survey Scheduled", label: "Survey Scheduled" },
    { value: "Survey Completed", label: "Survey Completed" },
    { value: "Ready to book", label: "Ready to book" },
    { value: "Pending", label: "Pending" },
    { value: "Booked", label: "Booked" },
    { value: "Lost", label: "Lost" },
    { value: "Inactive", label: "Inactive" },
  ];

  const moveTypeOptions = [
    { value: "", label: "Select" },
    { value: "interstate", label: "Interstate" },
    { value: "local", label: "Local/Intra" },
    { value: "international", label: "International" },
  ];

  return [
    {
      name: "datecreatedfrom",
      label: "Date Created From",
      key: "datecreatedfrom",
      type: "datepicker",
      selectedDate: selectedDate?.from || "",
      onChange: (value) => {
        setSelectedDate({
          ...selectedDate,
          from: value,
        });
      },
      maxDate: selectedDate?.to || new Date(),
      formGroupClass: "col-6 pr-2",
      className: "form-control",
    },
    {
      name: "datecreatedto",
      label: "Date Created To",
      key: "datecreatedto",
      type: "datepicker",
      selectedDate: selectedDate?.to || "",
      onChange: (value) => {
        setSelectedDate({
          ...selectedDate,
          to: value,
        });
      },
      minDate: selectedDate?.from || "",
      maxDate: new Date(),
      formGroupClass: "col-6 pr-2",
    },
    {
      name: "datemodifiedfrom",
      label: "Date Modified From",
      key: "datemodifiedfrom",
      type: "datepicker",
      selectedDate: selectedModifiedDate?.from || "",
      onChange: (value) => {
        setSelectedModifiedDate({
          ...selectedModifiedDate,
          from: value,
        });
      },
      maxDate: selectedModifiedDate?.to || new Date(),
      formGroupClass: "col-6 pr-2",
      className: "form-control",
    },
    {
      name: "datemodifiedto",
      label: "Date Modified To",
      key: "datemodifiedto",
      type: "datepicker",
      selectedDate: selectedModifiedDate?.to || "",
      onChange: (value) => {
        setSelectedModifiedDate({
          ...selectedModifiedDate,
          to: value,
        });
      },
      minDate: selectedModifiedDate?.from || "",
      maxDate: new Date(),
      formGroupClass: "col-6 pr-2",
    },
    {
      name: "leadstatus",
      label: "Lead Status",
      key: "leadstatus",
      type: "select",
      options: leadStatusOptions,
      defaultValue: filterSelectedValues?.leadstatus || "",
      formGroupClass: "col-6 pr-2",
    },
    {
      name: "movetype",
      label: "Move Type",
      key: "movetype",
      type: "select",
      options: moveTypeOptions,
      defaultValue: filterSelectedValues?.movetype || "",
      formGroupClass: "col-6 pr-2",
    },
  ];
};
