import fetchHandler from "../../utils/fetchHandler";
import { CAMPAIGN } from "../types";

export const createCampaign = (values, successHandler, errorHandler) => {
    const fetchOptions = {
        url: `campaigns/create`,
        method: "POST",
        secure: true,
        actionType: CAMPAIGN.CREATE_CAMPAIGN,
        body: JSON.stringify(values),
    };
    return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const getCampaignById = (campaignId, successHandler, errorHandler) => {
    const fetchOptions = {
        url: `campaigns/${campaignId}`,
        method: "GET",
        secure: true,
        actionType: CAMPAIGN.GET_CAMPAIGN_BY_ID,
    };
    return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const updateCampaign = (campaignId, values, successHandler, errorHandler) => {
    const fetchOptions = {
        url: `campaigns/${campaignId}`,
        method: "PUT",
        secure: true,
        actionType: CAMPAIGN.UPDATE_CAMPAIGN,
        body: JSON.stringify(values),
    };
    return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const getCampaignsByType = (type, successHandler, errorHandler) => {
    const fetchOptions = {
        url: `campaigns/list`,
        method: "POST",
        secure: true,
        actionType: CAMPAIGN.GET_CAMPAIGNS_BY_TYPE,
        body: JSON.stringify({ type })
    };
    return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const getBookerServiceProviders = (successHandler, errorHandler) => {
    const fetchOptions = {
        url: `campaigns/booker-service-providers`,
        method: "GET",
        secure: true,
        actionType: CAMPAIGN.GET_BOOKER_SERVICE_PROVIDERS
    };
    return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const getSalesPersons = (successHandler, errorHandler) => {
    const fetchOptions = {
        url: `campaigns/sales-persons`,
        method: "GET",
        secure: true,
        actionType: CAMPAIGN.GET_SALES_PERSONS
    };
    return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const createAutomatedCampaign = (values, successHandler, errorHandler) => {
    const fetchOptions = {
        url: `campaigns/create-automated-campaign`,
        method: "POST",
        secure: true,
        actionType: CAMPAIGN.CREATE_AUTOMATED_CAMPAIGN,
        body: JSON.stringify(values),
    };
    return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const deleteCampaign = (campaignId, successHandler, errorHandler) => {
    const fetchOptions = {
        url: `campaigns/${campaignId}`,
        method: "DELETE",
        secure: true,
        actionType: CAMPAIGN.DELETE_CAMPAIGN,
    };
    return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const getAutomatedCampaigns = (successHandler, errorHandler) => {
    const fetchOptions = {
        url: `campaigns/automated-campaigns`,
        method: "GET",
        secure: true,
        actionType: CAMPAIGN.GET_AUTOMATED_CAMPAIGNS,
    };
    return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const deleteAutomatedCampaign = (automatedCampaignId, successHandler, errorHandler) => {
    const fetchOptions = {
        url: `campaigns/automated-campaigns/${automatedCampaignId}`,
        method: "DELETE",
        secure: true,
        actionType: CAMPAIGN.DELETE_AUTOMATED_CAMPAIGN,
    };
    return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const getAutomatedCampaignForEdit = (automatedCampaignId, successHandler, errorHandler) => {
    const fetchOptions = {
        url: `campaigns/automated-campaigns/${automatedCampaignId}`,
        method: "GET",
        secure: true,
        actionType: CAMPAIGN.GET_AUTOMATED_CAMPAIGN_FOR_EDIT,
    };
    return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const updateAutomatedCampaign = (automatedCampaignId, values, successHandler, errorHandler) => {
    const fetchOptions = {
        url: `campaigns/automated-campaigns/${automatedCampaignId}`,
        method: "PUT",
        secure: true,
        actionType: CAMPAIGN.UPDATE_AUTOMATED_CAMPAIGN,
        body: JSON.stringify(values),
    };
    return fetchHandler(fetchOptions, successHandler, errorHandler);
}; 