import React, { useCallback, useState } from "react";
import Button from "../../../../utils/uiElements";
import ResizableGoogleStreetView from "../../../organic/ResizableGoogleStreetView";
import { isCompositeComponent } from "react-dom/test-utils";
//import { errorMessage } from "../../../utils/errorMessage";

const LeadsOwnerBlock = ({
  propertyOwnerInfo,
  setCommunicationFeedType,
  isPropertyOwnerInfoLoading,
}) => {

  const [showGoogleStreetView, setShowGoogleStreetView] = useState(false);
  const [showStreetAddress, setShowStreetAddress] = useState("");

  if (isPropertyOwnerInfoLoading) {
    return (
      <div className="d-flex flex-column h-100">
        <div className="d-flex flex-row flex-fill">
          <div className="pl-3 d-flex align-items-start w-60">
            <div>
              <div className="d-flex flex-row mb-4 align-items-center">
                <i className="relator-icon icon icon-users-check text-gray" />
                <h3 className="color-1 flex-fill ml-2 mb-0">HOME OWNER</h3>
              </div>

              <h2 className="pointer ellipsis text-primary">
                <div
                  className="skeleton-loader"
                  style={{ width: "200px", height: "28px" }}
                />
              </h2>
              <div className="d-flex align-items-center mb-2">
                <div
                  className="skeleton-loader"
                  style={{ width: "250px", height: "20px" }}
                />
              </div>

              <div className="d-flex">
                <div className="flex-fill mr-3 d-flex align-items-center">
                  <div
                    className="skeleton-loader"
                    style={{ width: "120px", height: "20px" }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  console.log("propertyOwnerInfo", propertyOwnerInfo);
  return (
    <div className="d-flex flex-column h-100">
      <div className="d-flex flex-row flex-fill">
        <div className="pl-3 d-flex align-items-start w-100">
          <div>
            <div className="d-flex flex-row mb-4 align-items-center">
              <i className="relator-icon icon icon-home text-gray" />
              <h3 className="color-1 flex-fill ml-2 mb-0">HOME OWNER</h3>
            </div>

            {propertyOwnerInfo &&
              (propertyOwnerInfo.firstName ||
                propertyOwnerInfo.name ||
                propertyOwnerInfo.email) ? (
              <div>
                <div className="d-flex">
                  <h2 className="ellipsis text-primary mt-1">
                    {propertyOwnerInfo.firstName}{" "}
                    {propertyOwnerInfo.name}
                  </h2>
                </div>

                <div className="d-flex align-items-center mb-2">
                  <span>{propertyOwnerInfo.address}</span>
                </div>
                <div className="d-flex">
                  <div className="flex-fill mr-3 d-flex align-items-center">
                    <span>{propertyOwnerInfo.phone}</span>
                  </div>
                </div>
              </div>
            ) : null}
          </div>
          {propertyOwnerInfo.address ? (
            <div className="w-100 d-flex flex-column align-items-end">
              <div>
                <Button
                  className="mb-auto btn btn-cell btn-light ml-2"
                  type="button"
                  onClick={() => {
                    setShowGoogleStreetView(true);
                    setShowStreetAddress(propertyOwnerInfo.address);
                  }}
                  value={<i className="icon icon-earth" />}
                  tooltip="Google StreetView"
                />
              </div>
            </div>
          ) : null}
        </div>
      </div>
      {propertyOwnerInfo &&
        (propertyOwnerInfo.address ||
          propertyOwnerInfo.email ||
          propertyOwnerInfo.phone) ? (
        <div className="d-flex justify-content-between">
          <div
            onClick={() => {
              setCommunicationFeedType(2);
            }}
            className="pl-3 pt-4 text-primary pointer ellipsis">
            View Communication Feed
          </div>
        </div>
      ) : null}
      <ResizableGoogleStreetView
        address={showStreetAddress}
        show={showGoogleStreetView}
        onClose={() => setShowGoogleStreetView(false)}
      />
    </div>
  );
};
export default LeadsOwnerBlock;
