import React from "react";
import moment from "moment";
import Tooltip from "../../utils/tooltip";
import { MOVE_TYPE_OPTIONS } from "../../constant/movscoutCampaignManagement";

export const getCampaignColumns = (history, onDeleteCampaign) => [
    {
        name: "Name",
        accessor: "templateName",
        className: "col-template-name",
        sortable: false,
        render: (row) => (
            <div
                className="text-primary pointer"
                onClick={() => history.push(`/campaigns/${row.campaignId}/edit`)}>
                {row.templateName}
            </div>
        ),
    },
    {
        name: "Date Created",
        accessor: "createdAt",
        className: "col-date-created",
        sortable: false,
        render: (row) => moment(row.createdAt).format("MM/DD/YYYY")
    },
    {
        name: "Status",
        accessor: "active",
        className: "col-status",
        sortable: false,
        render: (row) => (
            <span className={`badge badge-success`}>
                Active
            </span>
        ),
    },
    {
        name: "Actions",
        accessor: "actions",
        className: "col-actions text-right",
        headerClassName: "col-actions-header",
        sortable: false,
        render: (row) => (
            <div>
                <Tooltip
                    message="Edit"
                    component={
                        <button
                            type="button"
                            className="btn btn-cell btn-default ml-2"
                            onClick={() => history.push(`/campaigns/${row.campaignId}/edit`)}>
                            <i className="icon icon-edit" />
                        </button>
                    }
                />
                <Tooltip
                    message="Delete"
                    component={
                        <button
                            type="button"
                            className="btn btn-cell btn-default ml-2"
                            onClick={() => onDeleteCampaign(row)}>
                            <i className="icon icon-trash" />
                        </button>
                    }
                />
            </div>
        ),
    },
];

export const automatedColumns = (history, onDeleteAutomatedCampaign, onEditAutomatedCampaign) => [
    {
        name: "Name",
        accessor: "name",
        className: "col-automated-name",
        sortable: false,
        render: (row) => (
            <div
                className="text-primary pointer"
                onClick={() => history.push(`/automated-campaigns/${row.automatedCampaignId}/edit`)}>
                {row.name}
            </div>
        ),
    },
    {
        name: "Move Type",
        accessor: "moveType",
        className: "col-move-type",
        sortable: false,
        render: (row) => {
            const moveTypeOption = MOVE_TYPE_OPTIONS.find(option => option.value === row.moveType);
            return moveTypeOption ? moveTypeOption.label : row.moveType;
        }
    },
    {
        name: "Booker",
        accessor: "bookerName",
        className: "col-booker",
        sortable: false,
    },
    {
        name: "Sales Person ID",
        accessor: "salesPersonQLABId",
        className: "col-sales-person-id",
        sortable: false,
    },
    {
        name: "Sales Person",
        accessor: "salesPersonFullName",
        className: "col-sales-person",
        sortable: false,
    },
    {
        name: "Pre-sale",
        accessor: "preSaleCampaignName",
        className: "col-pre-sale",
        sortable: false,
        render: (row) => (
            <div>
                {row.preSaleCampaignName || "N/A"}
            </div>
        ),
    },
    {
        name: "Re-engage",
        accessor: "reEngageCampaignName",
        className: "col-re-engage",
        sortable: false,
        render: (row) => (
            <div>
                {row.reEngageCampaignName || "N/A"}
            </div>
        ),
    },
    {
        name: "Closer",
        accessor: "closerCampaignName",
        className: "col-closer",
        sortable: false,
        render: (row) => (
            <div>
                {row.closerCampaignName || "N/A"}
            </div>
        ),
    },
    {
        name: "Date Created",
        accessor: "creationTime",
        className: "col-date-created",
        sortable: false,
        render: (row) => moment(row.creationTime).format("MM/DD/YYYY"),
    },
    {
        name: "Status",
        accessor: "isActive",
        className: "col-status",
        sortable: false,
        render: (row) => (
            <span className={`badge ${row.isActive ? "badge-success" : "badge-danger"}`}>
                {row.isActive ? "Active" : "Inactive"}
            </span>
        ),
    },
    {
        name: "Actions",
        accessor: "actions",
        className: "col-actions text-right",
        headerClassName: "col-actions-header",
        sortable: false,
        render: (row) => (
            <div>
                <Tooltip
                    message="Edit"
                    component={
                        <button
                            type="button"
                            className="btn btn-cell btn-default ml-2"
                            onClick={() => onEditAutomatedCampaign(row)}>
                            <i className="icon icon-edit" />
                        </button>
                    }
                />
                <Tooltip
                    message="Delete"
                    component={
                        <button
                            type="button"
                            className="btn btn-cell btn-default ml-2"
                            onClick={() => onDeleteAutomatedCampaign(row)}>
                            <i className="icon icon-trash" />
                        </button>
                    }
                />
            </div>
        ),
    },
]; 