import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";

import { userTypes } from "../constant/userManagement";

import Campaigns from "../components/campaigns";
import CampaignCreate from "../components/campaigns/create/CampaignCreate";
import Dashboard from "../components/dashboard/Dashboard";
import MarketListing from "../components/markets/listing/MarketListing";
import Notifications from "../components/notification";
import MoveScout from "../components/plugins/movescout/MoveScout";
import MovescoutLeadDetails from "../components/plugins/movescout/view";
import PropertiesListing from "../components/properties/PropertiesListing";
import PropertyDetails from "../components/properties/view";
import RealtorListing from "../components/realtors/listing";
import RealtorDetails from "../components/realtors/view";
import Reports from "../components/reports";
import RealtorReports from "../components/reports/realtorReport";
import Settings from "../components/settings";
import TemplateListing from "../components/templates/listing";
import UserListing from "../components/users/listing/UserListing";
import UserDetails from "../components/users/view/UserDetails";

export const MainRouter = ({ userData, location, userDetails }) => {
  const BOLoggedIn = userData && userData.userType === userTypes.BO;
  const SALoggedIn = userData && userData.userType === userTypes.AD;
  return userData && userData.userId ? (
    <Switch location={location}>
      <Route exact path="/dashboard" component={Dashboard} />
      {!BOLoggedIn && <Route exact path="/users" component={UserListing} />}
      {!BOLoggedIn && (
        <Route exact path="/users/:id/view" component={UserDetails} />
      )}
      {!BOLoggedIn && <Route exact path="/markets" component={MarketListing} />}
      {!BOLoggedIn && (
        <Route
          exact
          path="/markets/:id/view"
          component={(props) => (
            <MarketListing {...props} userDetails={userData} />
          )}
        />
      )}
      {!BOLoggedIn && (
        <Route
          exact
          path="/markets/sub-markets/:id/view"
          component={(props) => (
            <MarketListing {...props} userDetails={userData} />
          )}
        />
      )}
      <Route
        exact
        path="/settings"
        component={(props) => (
          <Settings
            {...props}
            userDetails={{
              ...userData,
              licenseReallocationHistory:
                userDetails.licenseReallocationHistory,
            }}
          />
        )}
      />
      <Route exact path="/realtors" component={RealtorListing} />
      <Route
        exact
        path="/realtors/:id/view/:source"
        component={(props) => (
          <RealtorDetails {...props} userDetails={userData} />
        )}
      />
      <Route exact path="/realtors/:id/view" component={RealtorDetails} />
      <Route
        exact
        path="/templates"
        component={(props) => (
          <TemplateListing {...props} userDetails={userData} />
        )}
      />
      <Route exact path="/listings" component={PropertiesListing} />
      {!SALoggedIn && <Route exact path="/reports" component={Reports} />}
      <Route exact path="/listings/:id/view" component={PropertyDetails} />
      <Route exact path="/notifications" component={Notifications} />
      {SALoggedIn && (
        <Route exact path="/realtor-reports" component={RealtorReports} />
      )}
      <Route exact path="/campaigns" component={Campaigns} />
      <Route exact path="/campaigns/create" component={CampaignCreate} />
      <Route exact path="/campaigns/:id/edit" component={CampaignCreate} />
      <Route exact path="/plugins/movescout/leads" component={MoveScout} />
      <Route
        exact
        path="/plugins/movescout/leads/:id/view"
        component={MovescoutLeadDetails}
      />

      <Redirect to="/dashboard" />
    </Switch>
  ) : null;
};
