import React, { useEffect, useState } from "react";
import "./table.scss";

const classPrefix = "moovsoon";

export const Table = ({
	columns,
	data,
	onSort = null,
	sortBy = null,
	sortDir = "asc",
	expandableRows = false,
	expandedComponent = null,
	expandedRows = {},
	onToggleExpand = null,
}) => {
	const [sort, setSort] = useState({
		by: sortBy,
		order: sortDir,
	});

	useEffect(() => {
		sort && sort.by && sort.order && onSort && onSort(sort);
	}, [sort, onSort]);

	const renderTableColumnGroup = () => {
		return (
			<div className={`${classPrefix}-table-col-group`}>
				{columns.map((column, columnIndex) =>
					column.hide ? null : (
						<div
							key={columnIndex}
							className={`${classPrefix}-table-col ${column.className ? column.className : ""
								}`}
						/>
					)
				)}
			</div>
		);
	};

	const toggleSort = (event) => {
		if (event.accessor === sort.by) {
			setSort({ ...sort, order: sort.order === "asc" ? "desc" : "asc" });
		} else {
			setSort({
				by: event.sortField || event.accessor,
				order: event.defaultSortBy ?? "asc",
			});
		}
	};

	const renderSortIcon = (column) => {
		const accessor = column.accessor;
		if (accessor === sort.by) {
			return <i className={`icon icon-sort-${sort.order}`} />;
		}
		return <i className="icon icon-sort" />;
	};

	const renderTableHead = () => {
		return (
			<div className={`${classPrefix}-table-thead`}>
				<div className={`${classPrefix}-table-tr`}>
					{columns.map((column, columnIndex) => {
						const columnItem = { sortable: column.sortable ?? true, ...column };
						return column.hide ? null : (
							<div
								key={columnIndex}
								className={`${classPrefix}-table-th ${columnItem.className
									? columnItem.className
									: ""
									} ${columnItem.headerClassName
										? columnItem.headerClassName
										: ""
									} ${columnItem.sortable ? "sortable" : ""}`}
								onClick={() =>
									!columnItem.sortable
										? null
										: toggleSort(columnItem)
								}>
								{renderSortIcon(columnItem)} {columnItem.name}
							</div>
						);
					})}
				</div>
			</div>
		);
	};

	const toggleRow = (rowIndex) => {
		onToggleExpand && onToggleExpand(rowIndex);
	};

	const renderTableBody = () => {
		return (
			<div className={`${classPrefix}-table-tbody`}>
				{data.map((row, rowIndex) => (
					<React.Fragment key={rowIndex}>
						<div
							className={`${classPrefix}-table-tr ${expandableRows ? 'expandable' : ''} ${expandedRows[rowIndex] ? 'expanded' : ''}`}
						>
							{columns.map((column, columnIndex) =>
								column.hide ? null : (
									<div
										key={columnIndex}
										className={`${classPrefix}-table-td ${column.className || ''}`}
									>
										{column.render ? (
											column.render(row, column, rowIndex, columnIndex)
										) : row[column.accessor] || row[column.accessor] === 0 ? (
											row[column.accessor]
										) : (
											<span className={`${classPrefix}-table-td-placeholder`} />
										)}
									</div>
								)
							)}
						</div>
						{expandableRows && expandedRows[rowIndex] && expandedComponent && (
							<div className={`${classPrefix}-table-expanded-row`}>
								{expandedComponent(row)}
							</div>
						)}
					</React.Fragment>
				))}
			</div>
		);
	};

	return (
		<div className={`${classPrefix}-table`}>
			{columns && columns.length ? renderTableColumnGroup() : null}
			{columns && columns.length ? renderTableHead() : null}
			{data && data.length ? renderTableBody() : null}
		</div>
	);
};

export const Pagination = ({ total, count, page, onChange }) => {
	const maxPages = Math.ceil(total / count);
	const currentPage = Math.min(page, maxPages);
	const startItem = (currentPage - 1) * count + 1;
	const endItem = Math.min(currentPage * count, total);

	const generatePages = () => {
		const pages = new Set([1, maxPages]);

		for (let i = -1; i <= 2; i++) {
			const nearbyPage = currentPage + i;
			if (nearbyPage > 1 && nearbyPage < maxPages) pages.add(nearbyPage);
		}

		return Array.from(pages).sort((a, b) => a - b);
	};

	const pagesList = generatePages();

	return maxPages > 1 ? (
		<div className={`${classPrefix}-pagination`}>
			<div className={`${classPrefix}-pagination-one`}>
				Page {currentPage} of {maxPages} pages.
			</div>
			<div className={`${classPrefix}-pagination-two`}>
				Showing {startItem} to {endItem} of {total}.
			</div>
			<div className={`${classPrefix}-pagination-three`}>
				{/* Previous Button */}
				<div
					className={`pagination-btn pagination-prev ${currentPage > 1 ? "" : "disabled"
						}`}
					onClick={() => currentPage > 1 && onChange(currentPage - 1)}>
					&lt;
				</div>

				{/* Page Buttons */}
				{pagesList.map((pg) => (
					<div
						key={pg}
						className={`pagination-btn ${currentPage === pg ? "current" : ""
							}`}
						onClick={() => {
							if (currentPage !== pg) onChange(pg);
						}}>
						{pg}
					</div>
				))}

				{/* Next Button */}
				<div
					className={`pagination-btn pagination-next ${currentPage >= maxPages ? "disabled" : ""
						}`}
					onClick={() =>
						currentPage < maxPages && onChange(currentPage + 1)
					}>
					&gt;
				</div>
			</div>
		</div>
	) : null;
};
