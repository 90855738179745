import moment from "moment";
import React, { useState } from "react";
import { normalizePhone } from "../../../../constant/formValidators";
import InfoModal from "../../../../utils/infoModal";
import Tooltip from "../../../../utils/tooltip";
import { capitalizeWords } from "../helper";

const formatValue = (value) => {
  return value === "N" ? "N/A" : value;
};

const formatAddress = (address, city, state, zip) => {
  if (address && !/^tbd$/i.test(address)) return address;
  const formattedAddress = [city, state, zip].filter(Boolean).join(", ");
  return formattedAddress || "N/A";
};

const NotesContent = ({ notes }) => {
  return (
    <div className="notes-container">
      {notes.map((note) => (
        <div key={note.RowId} className="note-item">
          <div className="note-header">
            <span className="note-source">{note.NoteSource}</span>
            <span className="note-date">
              {note.DateTime
                ? new Date(note.DateTime).toLocaleDateString()
                : "-"}
            </span>
          </div>
          <div className="note-body">{note.Note}</div>
        </div>
      ))}
    </div>
  );
};

const StatusBadge = ({ status }) => {
  const validStatuses = [
    "new",
    "attempted-contact",
    "survey-scheduled",
    "survey-completed",
    "ready-to-book",
    "pending",
    "booked",
    "lost",
    "inactive",
  ];

  const statusClass = status
    ? validStatuses.includes(status.toLowerCase().replace(/\s+/g, "-"))
      ? `lead-badge-${status.toLowerCase().replace(/\s+/g, "-")}`
      : "lead-badge-default"
    : "lead-badge-default";

  return <div className={`status-badge ${statusClass}`}>{status}</div>;
};

const CampaignStatusIndicators = ({
  isLoading,
  activeCampaigns,
  onAutomationChange,
  automationEnabled,
}) => {
  if (isLoading) {
    return (
      <div className="campaign-status-indicators">
        <div
          className="skeleton-loader"
          style={{ width: "120px", height: "32px" }}></div>
        <div
          className="skeleton-loader"
          style={{ width: "120px", height: "32px" }}></div>
        <div
          className="skeleton-loader"
          style={{ width: "120px", height: "32px" }}></div>
      </div>
    );
  }

  const campaignTypes = [
    { type: "presale", label: "Pre-sale", icon: "icon-inbox" },
    { type: "reengage", label: "Re-engage", icon: "icon-sms" },
    { type: "closer", label: "Closer", icon: "icon-wallet" },
  ];

  return (
    <div className="campaign-status-container">
      <div className="campaign-status-indicators">
        {campaignTypes.map(({ type, label, icon }) => {
          const campaign = activeCampaigns?.[type];
          return (
            <Tooltip
              key={type}
              message={
                campaign
                  ? `Active Template: ${campaign.templateName}`
                  : `No active ${label} campaign`
              }
              component={
                <div
                  className={`campaign-indicator ${campaign ? "active" : "inactive"
                    }`}>
                  <i className={`icon ${icon}`} />
                  <span>{label}</span>
                  <div className="status-dot"></div>
                </div>
              }
            />
          );
        })}
      </div>
      <div className="automation-toggle-container">
        <label className="automation-label">
          <span>Automated Campaigns</span>
          <div className="toggle-switch">
            <input
              type="checkbox"
              checked={automationEnabled}
              onChange={(e) => onAutomationChange(e.target.checked)}
            />
            <span className="toggle-slider"></span>
          </div>
        </label>
      </div>
    </div>
  );
};

export const LeadDetailsCard = ({
  isLeadDetailsLoading,
  leadOpportunityDetails,
  onShowStreetView,
  onSetStreetAddress,
  activeCampaigns,
  automationEnabled,
  onAutomationChange,
}) => {
  const [isNotesModalOpen, setIsNotesModalOpen] = useState(false);

  if (isLeadDetailsLoading) {
    return (
      <div className="lead-details-card-container">
        <h2 className="mr-2 flex-fill">Leads Details</h2>
        <div className="lead-info-card">
          <div className="lead-info-container">
            <div className="lead-info-notes">
              <CampaignStatusIndicators
                isLoading={true}
                activeCampaigns={activeCampaigns}
                onAutomationChange={onAutomationChange}
                automationEnabled={automationEnabled}
              />
              <button className="btn btn-default btn-sm" disabled>
                Loading Notes...
              </button>
            </div>

            <div className="lead-info-sections">
              {/* Left Column */}
              <div className="lead-info-column">
                {/* Personal Information Section */}
                <div className="info-section">
                  <h3>Personal Information</h3>
                  <div className="info-grid">
                    <div className="info-item">
                      <label>Name</label>
                      <span
                        className="skeleton-loader"
                        style={{ width: "225px", height: "22px" }}></span>
                    </div>
                    <div className="info-item">
                      <label>Phone</label>
                      <span
                        className="skeleton-loader"
                        style={{ width: "225px", height: "22px" }}></span>
                    </div>
                    <div className="info-item">
                      <label>Email</label>
                      <span
                        className="skeleton-loader"
                        style={{ width: "225px", height: "22px" }}></span>
                    </div>
                    <div className="info-item">
                      <label>Lead ID</label>
                      <span
                        className="skeleton-loader"
                        style={{ width: "225px", height: "22px" }}></span>
                    </div>
                  </div>
                </div>

                {/* Survey Information */}
                <div className="info-section">
                  <h3>Survey Information</h3>
                  <div className="info-grid">
                    <div className="info-item">
                      <label>Survey Date</label>
                      <span
                        className="skeleton-loader"
                        style={{ width: "225px", height: "22px" }}></span>
                    </div>
                    <div className="info-item">
                      <label>Survey Time</label>
                      <span
                        className="skeleton-loader"
                        style={{ width: "225px", height: "22px" }}></span>
                    </div>
                    <div className="info-item">
                      <label>Requested Move Date</label>
                      <span
                        className="skeleton-loader"
                        style={{ width: "225px", height: "22px" }}></span>
                    </div>
                  </div>
                </div>

                {/* Status Information */}
                <div className="info-section">
                  <h3>Status Information</h3>
                  <div className="info-grid">
                    <div className="info-item">
                      <label>Status</label>
                      <span
                        className="skeleton-loader"
                        style={{ width: "225px", height: "22px" }}></span>
                    </div>
                    <div className="info-item">
                      <label>Last Contact Date</label>
                      <span
                        className="skeleton-loader"
                        style={{ width: "225px", height: "22px" }}></span>
                    </div>
                    <div className="info-item">
                      <label>MS QB MKT</label>
                      <span
                        className="skeleton-loader"
                        style={{ width: "225px", height: "22px" }}></span>
                    </div>
                    <div className="info-item">
                      <label>Created Date</label>
                      <span
                        className="skeleton-loader"
                        style={{ width: "225px", height: "22px" }}></span>
                    </div>
                  </div>
                </div>
              </div>

              {/* Right Column */}
              <div className="lead-info-column">
                {/* Move Details Section */}
                <div className="info-section">
                  <h3>Move Details</h3>
                  <div className="info-grid">
                    <div className="info-item">
                      <label>Move Type</label>
                      <span
                        className="skeleton-loader"
                        style={{ width: "225px", height: "22px" }}></span>
                    </div>
                    <div className="info-item">
                      <label>Dwelling Type</label>
                      <span
                        className="skeleton-loader"
                        style={{ width: "225px", height: "22px" }}></span>
                    </div>
                    <div className="info-item">
                      <label>Preferred Contact Time</label>
                      <span
                        className="skeleton-loader"
                        style={{ width: "225px", height: "22px" }}></span>
                    </div>
                    <div className="info-item">
                      <label>Street View</label>
                      <span
                        className="skeleton-loader"
                        style={{ width: "225px", height: "22px" }}></span>
                    </div>
                  </div>
                </div>

                {/* Origin Information */}
                <div className="info-section">
                  <h3>Origin Information</h3>
                  <div className="info-grid">
                    <div className="info-item">
                      <label>Origin Address</label>
                      <span
                        className="skeleton-loader"
                        style={{ width: "225px", height: "22px" }}></span>
                    </div>
                    <div className="info-item">
                      <label>Origin City</label>
                      <span
                        className="skeleton-loader"
                        style={{ width: "225px", height: "22px" }}></span>
                    </div>
                    <div className="info-item">
                      <label>Origin State</label>
                      <span
                        className="skeleton-loader"
                        style={{ width: "225px", height: "22px" }}></span>
                    </div>
                    <div className="info-item">
                      <label>Origin ZIP</label>
                      <span
                        className="skeleton-loader"
                        style={{ width: "225px", height: "22px" }}></span>
                    </div>
                  </div>
                </div>

                {/* Destination Information */}
                <div className="info-section">
                  <h3>Destination Information</h3>
                  <div className="info-grid">
                    <div className="info-item">
                      <label>Destination Address</label>
                      <span
                        className="skeleton-loader"
                        style={{ width: "225px", height: "22px" }}></span>
                    </div>
                    <div className="info-item">
                      <label>Destination City</label>
                      <span
                        className="skeleton-loader"
                        style={{ width: "225px", height: "22px" }}></span>
                    </div>
                    <div className="info-item">
                      <label>Destination State</label>
                      <span
                        className="skeleton-loader"
                        style={{ width: "225px", height: "22px" }}></span>
                    </div>
                    <div className="info-item">
                      <label>Destination ZIP</label>
                      <span
                        className="skeleton-loader"
                        style={{ width: "225px", height: "22px" }}></span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  const parsedPayload = JSON.parse(leadOpportunityDetails.payload);
  const ownerName = `${parsedPayload.FirstName || ""} ${parsedPayload.LastName || ""
    }`.trim();
  const phone = normalizePhone(
    parsedPayload.HomePhoneNumber ||
    normalizePhone(parsedPayload.CellPhoneNumber) ||
    "N/A"
  );
  const email = parsedPayload.EmailAddress || "N/A";
  const leadId = leadOpportunityDetails.opportunityId || "N/A";
  const originAddress = formatAddress(
    parsedPayload.OriginAddress,
    parsedPayload.OriginCity,
    parsedPayload.OriginState,
    parsedPayload.OriginZip
  );
  const destinationAddress = formatAddress(
    parsedPayload.DestinationAddress,
    parsedPayload.DestinationCity ? capitalizeWords(
      parsedPayload.DestinationCity,
    ) : "",
    parsedPayload.DestinationState,
    parsedPayload.DestinationZip
  );
  const preferredContactTime = parsedPayload.PreferedContactTime || "N/A";

  const surveyDate = parsedPayload.SurveyDate || "N/A";
  const surveyTime = parsedPayload.SurveyTime || "N/A";
  const requestedMoveDate = parsedPayload.RequestedMoveDate || "N/A";
  const expectedDeliveryDate = parsedPayload.ExpectedDeliveryDate || "N/A";
  const notes = parsedPayload.Notes;

  return (
    <div className="lead-details-card-container">
      <h2 className="mr-2 flex-fill">Leads Details</h2>
      <div className="lead-info-card">
        <div className="lead-info-container">
          <div className="lead-info-notes">
            <CampaignStatusIndicators
              isLoading={isLeadDetailsLoading}
              activeCampaigns={activeCampaigns}
              onAutomationChange={onAutomationChange}
              automationEnabled={automationEnabled}
            />
            <button
              className="btn btn-default btn-sm"
              onClick={() => setIsNotesModalOpen(true)}
              disabled={notes.length === 0}>
              {notes.length > 0 ? "Notes" : "No Notes"}
            </button>
          </div>

          <div className="lead-info-sections">
            {/* Left Column */}
            <div className="lead-info-column">
              {/* Personal Information Section */}
              <div className="info-section">
                <h3>Personal Information</h3>
                <div className="info-grid">
                  <div className="info-item">
                    <label>Name</label>
                    <span>{ownerName || "N/A"}</span>
                  </div>
                  <div className="info-item">
                    <label>Phone</label>
                    <span>{phone}</span>
                  </div>
                  <div className="info-item">
                    <label>Email</label>
                    <span>{email}</span>
                  </div>
                  <div className="info-item">
                    <label>Lead ID</label>
                    <span className="link">{leadId}</span>
                  </div>
                </div>
              </div>

              {/* Survey Information */}
              <div className="info-section">
                <h3>Survey Information</h3>
                <div className="info-grid">
                  <div className="info-item">
                    <label>Survey Date</label>
                    <span>{surveyDate}</span>
                  </div>
                  <div className="info-item">
                    <label>Survey Time</label>
                    <span>{surveyTime}</span>
                  </div>
                  <div className="info-item">
                    <label>Requested Move Date</label>
                    <span>{requestedMoveDate}</span>
                  </div>
                </div>
              </div>

              {/* Status Information */}
              <div className="info-section">
                <h3>Status Information</h3>
                <div className="info-grid">
                  <div className="info-item">
                    <label>Status</label>
                    <StatusBadge status={leadOpportunityDetails.disposition} />
                  </div>
                  <div className="info-item">
                    <label>Last Contact Date</label>
                    <span>{moment(new Date()).format("MM/DD/YYYY")}</span>
                  </div>
                  <div className="info-item">
                    <label>MS QB MKT</label>
                    <span>N/A</span>
                  </div>
                  <div className="info-item">
                    <label>Created Date</label>
                    <span>
                      {moment(leadOpportunityDetails.createdAt).format(
                        "MM/DD/YYYY"
                      )}
                    </span>
                  </div>
                </div>
              </div>
            </div>

            {/* Right Column */}
            <div className="lead-info-column">
              {/* Move Details Section */}
              <div className="info-section">
                <h3>Move Details</h3>
                <div className="info-grid">
                  <div className="info-item">
                    <label>Move Type</label>
                    <span className="highlight">
                      {leadOpportunityDetails.moveType || "N/A"}
                    </span>
                  </div>
                  <div className="info-item">
                    <label>Dwelling Type</label>
                    <span>{parsedPayload.DwelingType || "N/A"}</span>
                  </div>
                  <div className="info-item">
                    <label>Preferred Contact Time</label>
                    <span>{preferredContactTime}</span>
                  </div>
                  <div className="info-item">
                    <label>Street View</label>
                    <span
                      className="link"
                      onClick={() => {
                        onShowStreetView();
                        onSetStreetAddress(originAddress);
                      }}>
                      Google Street View
                    </span>
                  </div>
                </div>
              </div>

              {/* Origin Information */}
              <div className="info-section">
                <h3>Origin Information</h3>
                <div className="info-grid">
                  <div className="info-item">
                    <label>Origin Address</label>
                    <span>{originAddress}</span>
                  </div>
                  <div className="info-item">
                    <label>Origin City</label>
                    <span>{parsedPayload.OriginCity ? capitalizeWords(parsedPayload.OriginCity) : "N/A"}</span>
                  </div>
                  <div className="info-item">
                    <label>Origin State</label>
                    <span>{parsedPayload.OriginState || "N/A"}</span>
                  </div>
                  <div className="info-item">
                    <label>Origin ZIP</label>
                    <span>{parsedPayload.OriginZip || "N/A"}</span>
                  </div>
                </div>
              </div>

              {/* Destination Information */}
              <div className="info-section">
                <h3>Destination Information</h3>
                <div className="info-grid">
                  <div className="info-item">
                    <label>Destination Address</label>
                    <span>{destinationAddress}</span>
                  </div>
                  <div className="info-item">
                    <label>Destination City</label>
                    <span>{parsedPayload.DestinationCity ? capitalizeWords(parsedPayload.DestinationCity) : "N/A"}</span>
                  </div>
                  <div className="info-item">
                    <label>Destination State</label>
                    <span>{parsedPayload.DestinationState || "N/A"}</span>
                  </div>
                  <div className="info-item">
                    <label>Destination ZIP</label>
                    <span>{parsedPayload.DestinationZip || "N/A"}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <InfoModal
        show={isNotesModalOpen}
        onYes={() => setIsNotesModalOpen(false)}
        title="Notes"
        message={<NotesContent notes={notes} />}
      />
    </div>
  );
};
