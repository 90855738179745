import React from "react";
import RankImage from "../../../assets/rank-yellow.png";
import { Button } from "../../../utils/uiElements";

const RealtorBlock = ({
  propertyDetails,
  dispatch,
  loggedInAs,
  setCommunicationFeedType,
  history,
  match,
  isPropertyDetailsLoading,
}) => {
  if (isPropertyDetailsLoading) {
    return (
      <div className="d-flex flex-column h-100">
        <div className="d-flex flex-row flex-fill">
          <div className="pl-3 d-flex align-items-start w-60">
            <div>
              <div className="d-flex flex-row mb-4 align-items-center">
                <i className="relator-icon icon icon-users-check text-gray" />
                <h3 className="color-1 flex-fill ml-2 mb-0">REALTOR</h3>
              </div>

              <h2 className="pointer ellipsis text-primary">
                <div
                  className="skeleton-loader"
                  style={{ width: "200px", height: "28px" }}
                />
              </h2>
              <div className="d-flex align-items-center mb-2">
                <div
                  className="skeleton-loader"
                  style={{ width: "250px", height: "20px" }}
                />
              </div>

              <div className="d-flex">
                <div className="flex-fill mr-3 d-flex align-items-center">
                  <div
                    className="skeleton-loader"
                    style={{ width: "120px", height: "20px" }}
                  />
                </div>
              </div>
            </div>
          </div>
          {!loggedInAs("AD") && (
            <div className="d-flex flex-column align-items-end w-40 pr-2">
              <div className="d-flex align-items-center mt-auto">
                <img className="mr-2 rank-icon" src={RankImage} alt="rank" />
                <span className="mr-2">Realtor Rank</span>
                <h2 className="text-lg m-0">
                  <div
                    className="skeleton-loader"
                    style={{ width: "40px", height: "24px" }}
                  />
                </h2>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }

  const onSelect = (row) => {
    const id = match.params ? match.params.id : "";
    history.push(`/realtors/${row.realtorId}/view/${id}`);
  };

  return (
    <div className="d-flex flex-column h-100">
      <div className="d-flex flex-row flex-fill">
        <div className="pl-3 d-flex align-items-start w-60">
          <div>
            <div className="d-flex flex-row mb-4 align-items-center">
              <i className="relator-icon icon icon-users-check text-gray" />
              <h3 className="color-1 flex-fill ml-2 mb-0">REALTOR</h3>
            </div>
            {propertyDetails.realtorName ? (
              <>
                <h2
                  onClick={() => onSelect(propertyDetails)}
                  className="pointer ellipsis text-primary">
                  {propertyDetails.realtorName}
                </h2>
                <div className="d-flex align-items-center mb-2">
                  <span>{propertyDetails.realtorOfficeAddress}</span>
                </div>

                <div className="d-flex">
                  <div className="flex-fill mr-3 d-flex align-items-center">
                    <span>{propertyDetails.realtorMobile1}</span>
                  </div>
                </div>
              </>
            ) : null}
          </div>
        </div>

        {propertyDetails.realtorName && !loggedInAs("AD") ? (
          <div className="d-flex flex-column align-items-end w-40 pr-2">
            <div>
              {propertyDetails.realtorEmail ? (
                <Button
                  className="mb-auto btn btn-cell btn-light ml-2"
                  type="button"
                  onClick={() => {
                    dispatch({ manualProspectingType: 1 });
                    dispatch({ showMailPanel: true });
                  }}
                  value={<i className="icon icon-inbox text-gray" />}
                  tooltip="Send Email"
                />
              ) : null}
              {propertyDetails.realtorMobile1 ? (
                <Button
                  className="mb-auto btn btn-cell btn-light ml-2"
                  type="button"
                  onClick={() => {
                    dispatch({ showSMSPanel: true });
                    dispatch({ manualProspectingType: 1 });
                  }}
                  value={<i className="icon icon-sms text-gray" />}
                  tooltip="Send SMS"
                />
              ) : null}
              {propertyDetails.realtorOfficeAddress ? (
                <Button
                  className="mb-auto btn btn-cell btn-light ml-2"
                  type="button"
                  onClick={() => {
                    dispatch({ showPostCardPanel: true });
                    dispatch({ manualProspectingType: 1 });
                  }}
                  value={<i className="icon icon-wallet text-gray" />}
                  tooltip="Send Mailer"
                />
              ) : null}

              {propertyDetails.realtorOfficeAddress ? (
                <Button
                  className="mb-auto btn btn-cell btn-light ml-2"
                  type="button"
                  onClick={() => {
                    dispatch({ showGoogleStreetView: true });
                    dispatch({
                      showStreetAddress: propertyDetails.realtorOfficeAddress,
                    });
                  }}
                  value={<i className="icon icon-earth" />}
                  tooltip="Google StreetView"
                />
              ) : null}
            </div>
            <div className="d-flex align-items-center mt-auto">
              <img className="mr-2 rank-icon" src={RankImage} alt="rank" />
              <span className="mr-2">Realtor Rank</span>
              <h2 className="text-lg m-0">{propertyDetails.realtorRank}</h2>
            </div>
          </div>
        ) : null}
      </div>

      {propertyDetails.realtorName ? (
        <div
          onClick={() => {
            setCommunicationFeedType(1);
          }}
          className="pl-3 pt-4 text-primary pointer ellipsis">
          View Communication Feed
        </div>
      ) : null}
    </div>
  );
};
export default RealtorBlock;
