import React from "react";

const LeadsRealtorBlock = ({
  propertyDetails,
  setCommunicationFeedType,
  history,
  match,
  isPropertyDetailsLoading,
}) => {
  if (isPropertyDetailsLoading) {
    return (
      <div className="d-flex flex-column h-100">
        <div className="d-flex flex-row flex-fill">
          <div className="pl-3 d-flex align-items-start w-60">
            <div>
              <div className="d-flex flex-row mb-4 align-items-center">
                <i className="relator-icon icon icon-users-check text-gray" />
                <h3 className="color-1 flex-fill ml-2 mb-0">REALTOR</h3>
              </div>

              <h2 className="pointer ellipsis text-primary">
                <div
                  className="skeleton-loader"
                  style={{ width: "200px", height: "28px" }}
                />
              </h2>
              <div className="d-flex align-items-center mb-2">
                <div
                  className="skeleton-loader"
                  style={{ width: "250px", height: "20px" }}
                />
              </div>

              <div className="d-flex">
                <div className="flex-fill mr-3 d-flex align-items-center">
                  <div
                    className="skeleton-loader"
                    style={{ width: "120px", height: "20px" }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  const onSelect = (row) => {
    const id = match.params ? match.params.id : "";
    history.push(`/realtors/${row.realtorId}/view/${id}`);
  };

  return (
    <div className="d-flex flex-column h-100">
      <div className="d-flex flex-row flex-fill">
        <div className="pl-3 d-flex align-items-start w-60">
          <div>
            <div className="d-flex flex-row mb-4 align-items-center">
              <i className="relator-icon icon icon-users-check text-gray" />
              <h3 className="color-1 flex-fill ml-2 mb-0">REALTOR</h3>
            </div>
            {propertyDetails.realtorName ? (
              <>
                <h2
                  onClick={() => onSelect(propertyDetails)}
                  className="pointer ellipsis text-primary">
                  {propertyDetails.realtorName}
                </h2>
                <div className="d-flex align-items-center mb-2">
                  <span>{propertyDetails.realtorOfficeAddress}</span>
                </div>

                <div className="d-flex">
                  <div className="flex-fill mr-3 d-flex align-items-center">
                    <span>{propertyDetails.realtorMobile1}</span>
                  </div>
                </div>
              </>
            ) : null}
          </div>
        </div>
      </div>

      {propertyDetails.realtorName ? (
        <div
          onClick={() => {
            setCommunicationFeedType(1);
          }}
          className="pl-3 pt-4 text-primary pointer ellipsis">
          View Communication Feed
        </div>
      ) : null}
    </div>
  );
};
export default LeadsRealtorBlock;
