import React, { useState, useEffect } from "react";
import { Field, reduxForm } from "redux-form";
import { connect } from "react-redux";
import FormGroup from "../../../../utils/formGroup";
import { getCampaignsByType } from "../../../../store/actions";
import './CampaignPanel.scss'

const CampaignPanel = ({
    handleSubmit,
    campaigns,
    initialValues,
    onSave,
    setShowCampaignPanel,
    getCampaignsByType
}) => {
    const [campaignOptions, setCampaignOptions] = useState({
        "Pre-sale": [],
        "Re-engage": [],
        "Closer": []
    });
    const [isLoading, setIsLoading] = useState({
        "Pre-sale": true,
        "Re-engage": true,
        "Closer": true
    });

    const campaignTypes = {
        "Pre-sale": "Select Pre-sale Campaign",
        "Re-engage": "Select Re-engage Campaign",
        "Closer": "Select Closer Campaign"
    };

    // Map campaign types to their API type values
    const typeMap = {
        "Pre-sale": 1,
        "Re-engage": 2,
        "Closer": 3
    };

    useEffect(() => {
        // Fetch campaigns for each type
        Object.entries(typeMap).forEach(([type, typeValue]) => {
            setIsLoading(prev => ({ ...prev, [type]: true }));

            getCampaignsByType(
                typeValue,
                (response) => {
                    if (response && response.result) {
                        setCampaignOptions(prev => ({
                            ...prev,
                            [type]: response.result || []
                        }));
                    }
                    setIsLoading(prev => ({ ...prev, [type]: false }));
                },
                (error) => {
                    console.error(`Error fetching ${type} campaigns:`, error);
                    setIsLoading(prev => ({ ...prev, [type]: false }));
                }
            );
        });
    }, [getCampaignsByType]);

    const formatCampaignOptions = (type) => {
        if (isLoading[type]) {
            return [{ value: "", label: "Loading campaigns..." }];
        }

        if (!campaignOptions[type] || campaignOptions[type].length === 0) {
            return [{ value: "", label: `No ${type} campaigns available` }];
        }

        return campaignOptions[type].map(campaign => ({
            value: campaign.campaignId,
            label: campaign.templateName
        }));
    };

    return (
        <form onSubmit={handleSubmit(onSave)}>
            <div className="d-flex">
                <h2 className="mb-4 flex-fill">Add to Campaign</h2>
                <div
                    className="ml-3 btn btn-default btn-sm mb-3"
                    onClick={() => setShowCampaignPanel(false)}
                >
                    <span>&lt;</span> Back
                </div>
            </div>

            <div className="campaign-sections">
                {Object.entries(campaignTypes).map(([type, label]) => {
                    const options = formatCampaignOptions(type);
                    const campaignFieldName = `campaign_${type.toLowerCase().replace('-', '')}`;

                    return (
                        <div key={type} className="campaign-section">
                            <h3 className="campaign-type-header text-uppercase">{type}</h3>
                            <div className="form-container">
                                <Field
                                    name={campaignFieldName}
                                    label={label}
                                    component={FormGroup}
                                    type="select"
                                    className="form-control"
                                    options={options}
                                    disabled={isLoading[type]}
                                />
                            </div>
                        </div>
                    );
                })}
            </div>

            <div className="d-flex justify-content-end mt-4">
                <button type="submit" className="btn btn-primary">
                    Save Changes
                </button>
            </div>
        </form>
    );
};

const mapDispatchToProps = {
    getCampaignsByType
};

export default connect(null, mapDispatchToProps)(reduxForm({
    form: 'campaignPanel'
})(CampaignPanel)); 