import React, { memo, useCallback, useEffect, useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import { ResizableBox } from "react-resizable";
import "react-resizable/css/styles.css";
import "./resizableGoogleStreetView.scss";
const GOOGLEMAP_APIKEYS = process.env.REACT_APP_GOOGLEMAP_APIKEYS;

const ResizableGoogleStreetView = ({ address, show, onClose }) => {
  const [width, setWidth] = useState(1200);
  const [height, setHeight] = useState(600);
  const [error, setError] = useState(null);
  const mapRef = useRef(null);

  const fetchCoordinates = async (address) => {
    try {
      return await fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
          address
        )}&key=${GOOGLEMAP_APIKEYS}`
      ).then((response) => {
        return response.json();
      });
    } catch (error) {
      console.error("Error fetching coordinates:", error);
      return null;
    }
  };

  const initializeStreetView = useCallback((lat, lng) => {
    if (!window.google || !mapRef.current) return;
    const streetViewService = new window.google.maps.StreetViewService();
    streetViewService.getPanorama(
      { location: { lat, lng }, radius: 50 },
      (data, status) => {
        if (status === window.google.maps.StreetViewStatus.OK) {
          new window.google.maps.StreetViewPanorama(mapRef.current, {
            pano: data.location.pano,
            pov: { heading: 34, pitch: 10, zoom: 1 },
          });
        } else {
          setError(
            `Street View data not found for the provided address [${address}]`
          );
        }
      }
    );
  }, [address]);

  useEffect(() => {
    if (show && address && window.google) {
      fetchCoordinates(address).then((data) => {
        if (data && data.results && data.results.length > 0) {
          //console.log('Fetched coordinates:', address, data);
          setError(null);
          const { lat, lng } = data.results[0].geometry.location;
          initializeStreetView(lat, lng);
        } else {
          setError(
            `Could not fetch coordinates for the provided address [${address}]`
          );
        }
      });
    }
  }, [show, address, initializeStreetView]);

  useEffect(() => {
    const loadScript = (url, callback) => {
      if (document.querySelector(`script[src="${url}"]`)) {
        return callback();
      }

      const script = document.createElement("script");
      script.type = "text/javascript";
      script.src = url;
      script.onload = callback;
      document.head.appendChild(script);
    };

    const initializeGoogleMaps = () => {
      if (!window.google) {
        loadScript(
          `https://maps.googleapis.com/maps/api/js?key=${GOOGLEMAP_APIKEYS}`,
          () => { }
        );
      }
    };

    initializeGoogleMaps();
  }, []);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Escape") {
        onClose();
      }
    };

    window.addEventListener("keydown", handleKeyDown);
    return () => window.removeEventListener("keydown", handleKeyDown);
  }, [onClose]);

  if (!show || !address) {
    return null;
  }

  return (
    <Modal show={show} backdrop="static" animation={false}>
      <div className="overlay">
        <div className="popupStyles">
          {error ? (
            <div className="error-container">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="48"
                height="48"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="lucide lucide-map-pin-off">
                <path d="M12.75 7.09a3 3 0 0 1 2.16 2.16" />
                <path d="M17.072 17.072c-1.634 2.17-3.527 3.912-4.471 4.727a1 1 0 0 1-1.202 0C9.539 20.193 4 14.993 4 10a8 8 0 0 1 1.432-4.568" />
                <path d="m2 2 20 20" />
                <path d="M8.475 2.818A8 8 0 0 1 20 10c0 1.183-.31 2.377-.81 3.533" />
                <path d="M9.13 9.13a3 3 0 0 0 3.74 3.74" />
              </svg>
              <h3 className="error-title">Street View Error</h3>
              <p className="error-message">{error}</p>
              <button className="btn btn-primary" onClick={onClose}>
                Close
              </button>
            </div>
          ) : (
            <ResizableBox
              width={width}
              height={height}
              minConstraints={[300, 300]}
              maxConstraints={[1200, 600]}
              onResizeStop={(e, data) => {
                setWidth(data.size.width);
                setHeight(data.size.height);
              }}
              handle={<span className="popupHandles" />}>
              <div
                style={{ width: "100%", height: "100%", position: "relative" }}>
                <div
                  id="map"
                  ref={mapRef}
                  style={{ width: "100%", height: "100%" }}></div>
              </div>
            </ResizableBox>
          )}
        </div>
      </div>
      <button onClick={onClose} className="closeButtonStyles">
        &times;
      </button>
    </Modal>
  );
};

export default memo(ResizableGoogleStreetView);
